import React from 'react';
import SectionTitle from "./SectionTitle.jsx";
import BackButton from './BackButton';
import {AppContext} from "../contexts/PortalContext";
import {makeStyles, AppBar, Toolbar} from "@material-ui/core";
import {Typography} from '@material-ui/core';
import QuickGuideContent from "./QuickGuideContent";
// SASS Styles
import style from './styles/HelpSection.module.scss';
import ErrorBoundary from "./ErrorBoundary";

const useStyles = makeStyles(theme => ({
    contactText: {
        textTransform: 'uppercase',
        color: theme.palette.primary.contrastText
    },
    guideContacts: {
        gridColumn: '1/-1',
        margin: '24px auto 0 auto',
        background: theme.palette.primary.main,
        color: theme.palette.grey[100],
        padding: '2vw',
        textAlign: 'center'
    },
    guideContainer: {
        gridColumn: '1/-1',
        width: '90%',
        maxWidth: '1080px',
        margin: '3vh auto',
        background: theme.palette.grey[50],
        padding: 0,
        minHeight: '90vh',
        fontSize: '1.3em',
        boxShadow: '3px 3px 15px #000',
    },
}));

function Help(props) {
    const classes = useStyles();

    return (
        <AppContext.Consumer>
            {(context) => (
                <div>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={style.history}>
                            <BackButton history={props.history}/>
                        </Toolbar>
                    </AppBar>
                    <SectionTitle variant="h1" style={style.welcomeHelp} title="Quick Guide"/>
                    <div className={classes.guideContainer}>
                        <ErrorBoundary>
                          <QuickGuideContent/>
                          {/*<div>*/}
                          {/*  <img src={context.state.contextConfig.quickGuide} alt="quick guide" style={{width: '100%'}} />*/}
                          {/*</div>*/}
                        </ErrorBoundary>
                        <div className={classes.guideContacts}>
                            <Typography className={classes.contactText}
                                        component="h2">Contact {context.state.contextConfig.name} </Typography>
                            <hr/>
                            <p>email: {context.state.contextConfig.portalEmail}</p>
                            <p>Phone #: {context.state.contextConfig.portalHelpLine}</p>
                          </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
}

export default Help;
