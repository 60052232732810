import React, {Component} from 'react';
import {AppContext} from '../../contexts/PortalContext.js';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import {catchAxios, decodeToken} from "../utils/utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Loader from "../Loader";
import Loading from "../Loading";
import {FadeIn, enterComponent} from '../animated.js';
//Material Ui Components
import {
    Grid,
    Paper,
    TextField,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    Select,
    OutlinedInput,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core'
import {withTheme} from "@material-ui/core";
import SectionTitle from "../SectionTitle.jsx";

/**
 *  This Component should only render if the user logged in has Admin status, or if the user has multiple billing accounts. It will render a search bar where users can filter results.  The search filter will filter both the Organization Name as well as the Account number. A user can select the account they wish to log in with and will be taken to the portal landing page after selecting an account.
 *  @module MultipleAccounts
 */
// SASS Styles
import style from '../styles/MainLayout.module.scss';
import {siteTypeByBusinessUnit} from "../../App";
import LoaderPage from "../LoaderPage";

const FadeTableRow = FadeIn('div');
const AnimatePaper = enterComponent(Paper);

class MultipleAccounts extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            billingAccountNr: 0,
            filteredField: '',
            isSubmitting: false,
            redirect: false,
            businessUnit: '',
            selectedBusinessUnit: '',
            labelWidth: 0,
            pagination: {
                total: 0,
                items: [],
                currentPage: 0,
                hasMore: false,
            },
            errMsg: '',
        };

        this.timeIntervalFilter = null;
        this.businessUnit = React.createRef();

        // handles the queries from the text input
        this.handleChange = event => {
            // if the event is from the select element filter based on business unit (the ALL option must return an empty string to the server)

            // if the event comes from a radio button set the business unit for the selected org in the local state.
            // alternatively if the event is from the textfield filter the list of results.
            if (event.target.name === 'billingAccountNr') {
                this.setState({[event.target.name]: event.target.value, isSubmitting: false});
            } else if (event.target.name === 'filteredField') {
                this.setState({
                    [event.target.name]: event.target.value,
                    isSubmitting: true,
                    billingAccountNr: 0,
                    loading: false
                });
                clearTimeout(this.timeIntervalFilter);
                this.timeIntervalFilter = setTimeout(() => {
                    this.setState({
                        pagination: {
                            ...this.state.pagination,
                            items: [],
                            currentPage: 0,
                        }
                    });
                    this.getBusinessAccountItems();
                }, 800);
            } else if (event.target.name === 'businessUnit') {
                this.setState({[event.target.name]: event.target.value});

                clearInterval(this.timeIntervalFilter);
                this.timeIntervalFilter = setInterval(() => {
                    if (!this.state.loading) {
                        clearInterval(this.timeIntervalFilter);
                        this.setState((prevState) => {
                            return {
                                pagination: {
                                    ...this.state.pagination,
                                    items: [],
                                    currentPage: 0,
                                },
                                isSubmitting: true,
                                billingAccountNr: 0,
                                loading: false
                            }
                        }, this.getBusinessAccountItems);
                    }
                }, 100);
            }
        };

        this.handleSubmit = async () => {
            this.setState({
                loading: true,
                isSubmitting: true
            });

            const authToken = sessionStorage.getItem('token');
            const token = decodeToken(authToken);
            const multiAccount = (token && token.user && token.user.multiAccount) ? true : false;

            // Account number can only ever change for a multi account user
            if (multiAccount) {
                // Set business unit before setting active billing account number
                await this.context.state.dispatch({
                    type: 'SET_BUSINESS_UNIT',
                    payload: siteTypeByBusinessUnit(this.state.selectedBusinessUnit)
                });

                axios.post(`/auth/switch-account`, {
                        accountNo: this.state.billingAccountNr
                    }, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    }
                ).then(async (res) => {
                    this.setState({
                        isSubmitting: false,
                        loading: false,
                    });
                    if (res.data.status) {
                        await this.context.state.dispatch({
                            type: 'SET_ACTIVE_BILLING_ACCOUNT',
                            payload: res.data.data.billingaccountno
                        });
                        this.setState({redirect: true});
                    } else {
                        this.setState({errMsg: res.data.msg});
                    }
                }).catch((err) => {
                    catchAxios(err);
                });
            } else {
                this.setState({
                    isSubmitting: false,
                    loading: false,
                    redirect: false
                });
            }
        };

        this.clearErrMsg = async () => {
            this.setState({errMsg: ''});
        };

        this.getBusinessAccountItems = async () => {
            const authToken = sessionStorage.token;
            const limitItems = 30;

            if (authToken && !this.state.loading) {
                this.setState({loading: true});
                axios.post(`/profile/business`, {
                        offset: this.state.pagination.currentPage,
                        limit: limitItems,
                        filterQuery: this.state.filteredField,
                        businessUnit: this.state.businessUnit
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    }
                ).then((res) => {
                    if (this._isMounted) {
                        if (res.data.status) {
                            if (res.data.data.length === 1 && res.data.total === 1) {
                                this.props.location.pathname = '/business/my-account';
                            } else {
                                const totalItems = this.state.pagination.items.concat(Array.from(res.data.data));
                                this.setState({
                                    pagination: {
                                        ...this.state.pagination,
                                        total: res.data.total,
                                        items: totalItems,
                                        hasMore: (res.data.total > totalItems.length),
                                        currentPage: (limitItems + this.state.pagination.currentPage)
                                    }
                                });
                            }
                        } else {
                            this.setState({
                                errMsg: `Something went wrong: ${res.data.data.msg}.  Please log in and try again.`
                            })
                        }
                        this.setState({loading: false});
                    }
                }).catch((err) => {
                    this.setState({loading: false});
                    catchAxios(err);
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const recaptcha = document.querySelectorAll('.grecaptcha-badge');
        if (recaptcha[0]) {
            recaptcha[0].classList.add('displayNone');
        }
    }

    componentDidMount() {
        const token = decodeToken(sessionStorage.getItem('token'));
        const isAdmin = (token && token.user && token.user.isAdmin) ? true : false;

        // Initialize business unit value for dropdown if user is admin and businessUnit exists
        if (isAdmin && this.context && this.context.state && this.context.state.businessUnit) {
            this.setState({
                businessUnit: this.context.state.businessUnit
            });
        }

        this._isMounted = true;
        isAdmin &&
        this.setState({labelWidth: this.businessUnit.current.offsetWidth});

        /**
         * Check if an activeBillingAccount number already exists in context
         * An account number in context means the customer clicked on switch account link
         * Allows back button navigation without side effects
         */
        if (!this.context.state.activeBillingAccount) {
            // reset the billing account on the app context.
            this.context.state.dispatch({
                type: "SET_ACTIVE_BILLING_ACCOUNT",
                payload: 0
            });
        }

        // disable submit until a user selects a org to proceed.
        if (this.state.billingAccountNr === 0) {
            this.setState({isSubmitting: true});
        } else {
            this.setState({isSubmitting: false});
        }
        this.getBusinessAccountItems();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const token = decodeToken(sessionStorage.getItem('token'));
        const isAdmin = (token && token.user && token.user.isAdmin) ? true : false;
        const scrollMessage = (this.state.loading) ? 'Retrieving Accounts... ' : 'No more accounts matching your criteria.';

        const classes = {
            titleRow: {
                backgroundColor: this.props.theme.palette.primary.main,
                color: this.props.theme.palette.common.white,
                padding: '8px 0',
                position: 'sticky',
                top: 0,
            },
        };

        if (this.state.redirect) return <Redirect to='/business/my-account'/>;

        return (
            <>
                <LoaderPage/>
                <SectionTitle variant="h1" title="Select Billing Account" className={style.welcome}/>
                <Grid container justify="center" alignItems="center" spacing={0} style={{marginTop: '1.5vh'}}>
                    {this.state.errMsg === '' ?
                        <AnimatePaper square={true} className={style.tableSize}>
                            <Grid container justify="center" alignItems="center" spacing={0}
                                  style={{margin: '1vh auto'}}>
                                <Grid item xs={11} md={8} className={style.searchContainer}>
                                    {/* Filter Results */}
                                    <TextField
                                        id="filter"
                                        label="Filter Results"
                                        value={this.state.filteredField}
                                        name="filteredField"
                                        type="filter"
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        className={`${style.searchField} ${isAdmin ? style.admin : style.multi}`}
                                        fullWidth
                                    />
                                    {isAdmin &&
                                    <FormControl variant="outlined" className={style.selectField}>
                                        <InputLabel ref={this.businessUnit} htmlFor="business-unit">B.
                                            Unit
                                        </InputLabel>
                                        <Select
                                            value={this.state.businessUnit}
                                            name="businessUnit"
                                            onChange={this.handleChange}
                                            className={style.selectField}
                                            input={
                                                <OutlinedInput labelWidth={this.state.labelWidth} name="B. Unit"
                                                               id="business-unit"/>
                                            }
                                        >
                                            <MenuItem value="WNI">WiLine</MenuItem>
                                            <MenuItem value="SNI">Stephouse</MenuItem>
                                            <MenuItem value="ALL">All</MenuItem>
                                        </Select>
                                    </FormControl>
                                    }

                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <fieldset>
                                        <form onSubmit={this.handleSubmit} style={{margin: '0 auto'}}>
                                            <div className={style.formContainer}>
                                                <div cellPadding="2" cellSpacing="2"
                                                     className={`${style.table} ${style.multipleAccountsTable}`}
                                                     style={{overflowX: 'hidden'}} id="multiAccount">
                                                    <div style={classes.titleRow}>
                                                        <Typography variant="body1"
                                                                    className={`${style.tableCell} ${style.tableBillNo} ${style.centerText}`}>
                                                            <b>Account</b></Typography>
                                                        <Typography variant="body1"
                                                                    className={`${style.tableCell} ${style.tableOrgName}`}><b>Organization
                                                            Name</b></Typography>
                                                        {/* only show company type if user is an admin */}
                                                        {isAdmin &&
                                                        <Typography variant="body1"
                                                                    className={`${style.tableCell} ${style.subsidiary}`}>
                                                            <b>Subsidiary</b></Typography>}
                                                    </div>
                                                    <InfiniteScroll
                                                        style={{overflowY: "overlay"}}
                                                        dataLength={this.state.pagination.items.length}
                                                        next={this.getBusinessAccountItems}
                                                        hasMore={this.state.pagination.hasMore}
                                                        loader={<Loader height="300px"/>}
                                                        endMessage={<Typography variant="h5" color="primary"
                                                                                style={{padding: '8px'}}>{scrollMessage} {this.state.loading &&
                                                        <FontAwesomeIcon
                                                            style={{color: this.context.state.contextConfig.color}}
                                                            icon={['far', 'spinner']}
                                                            className="fa-spin"/>}</Typography>}
                                                        height={((window.innerHeight * 42) / 100)}
                                                    >
                                                        {this.state.pagination.items.length > 0 && this.state.pagination.items.map((org, i) => (
                                                            <FadeTableRow
                                                                className={
                                                                    `${style.tableRow}
                                                                     ${(this.state.billingAccountNr === org.billingaccountno.toString()) ? style.active : null}`
                                                                }
                                                                key={`${org.orgid}-${i}`}
                                                                delay={`${parseInt(i) * 0.5}ms`}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        billingAccountNr: org.billingaccountno,
                                                                        selectedBusinessUnit: org.business_unit,
                                                                        isSubmitting: false
                                                                    });
                                                                }}
                                                            >
                                                                <div className={`${style.tableBillNo}`}>
                                                                    <RadioGroup
                                                                        aria-label="Account Nr.">
                                                                        <FormControlLabel
                                                                            value={org.billingaccountno}
                                                                            control={<Radio
                                                                                checked={this.state.billingAccountNr === org.billingaccountno.toString()}
                                                                                onChange={this.handleChange}
                                                                                color="primary"
                                                                                value={org.billingaccountno}
                                                                                name="billingAccountNr"
                                                                                aria-label={org.org_name}
                                                                                label={org.billingaccountno}/>}
                                                                            label={org.billingaccountno}/>
                                                                    </RadioGroup>
                                                                </div>
                                                                <Typography variant="body1"
                                                                            className={`${style.tableOrgName}`}>{org.org_name}</Typography>
                                                                {/* only show if user is an admin */}
                                                                {isAdmin &&
                                                                <Typography variant="body1"
                                                                            className={`${style.subsidiary}`}>{org.business_unit}</Typography>}
                                                            </FadeTableRow>
                                                        ))
                                                        }
                                                    </InfiniteScroll>
                                                    {/*</tbody>*/}
                                                </div>
                                            </div>
                                            <div className={style.buttonContainer}>
                                                <Button
                                                    variant="contained"
                                                    style={{width: '100%'}}
                                                    disabled={this.state.isSubmitting || this.state.billingAccountNr === 0}
                                                    onClick={() => this.setState({
                                                        billingAccountNr: 0,
                                                        isSubmitting: true
                                                    })}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.isSubmitting || this.state.billingAccountNr === 0}
                                                    onClick={this.handleSubmit}
                                                    style={{width: '100%'}}
                                                >
                                                    <Loading
                                                        submitText="submit"
                                                        loadingText="loading"
                                                        loading={this.state.loading}
                                                    />
                                                </Button>
                                            </div>
                                        </form>
                                    </fieldset>
                                </Grid>
                            </Grid>
                        </AnimatePaper>
                        :
                        <AnimatePaper square={true} className={style.tableSize}>
                            <Typography variant="h5" color="primary"
                                        style={{padding: '8px'}}>{this.state.errMsg}
                                <Button
                                    variant="outline-secondary"
                                    className={style.button}
                                    onClick={this.clearErrMsg}
                                    style={{
                                        float: 'right',
                                        border: '1px solid #bbb',
                                        padding: '0'
                                    }}
                                >
                                    Close
                                </Button>
                            </Typography>
                        </AnimatePaper>
                    }
                </Grid>
            </>
        )
    }
}

MultipleAccounts.contextType = AppContext;
export default withTheme(MultipleAccounts);
