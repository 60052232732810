import io from 'socket.io-client';
import {clearServerSession} from './utils/utils';

let socket;
let isConnected = false;

/**
 * @return {mix}
 */
function SocketIO(method, action, params) {

    //@TODO keep this return util fix the socket issue
    return socket;

    const token = sessionStorage.getItem('token');

    if (token) {

        if (typeof method !== 'undefined' && method === 'unique_connection') {
            return (socket) ? socket : false;
        }

        if (typeof socket === 'undefined' || !isConnected) {
            const socketOpt = {
                query: {
                    token: token
                },
                enablesXDR: true,
                forceNode: true,
                // transportOptions: {
                //     polling: {
                //         extraHeaders: {
                //             'Authorization': `Bearer ${token}`,
                //         }
                //     },
                // },
            };
            //socket = (process.env.NODE_ENV === 'development') ? io((process.env.REACT_APP_SOCKETIO_SERVER || ''), socketOpt) : io(window.location.href, socketOpt);
            socket = (process.env.NODE_ENV === 'development') ? io((process.env.REACT_APP_SOCKETIO_SERVER || ''), socketOpt) : io('http://localsocket:5000', socketOpt);

            socket.on('connect', () => {
                isConnected = true;
            });
            socket.on('forceDisconnectUser', (socktId) => {
                if (typeof socket !== 'undefined' && socktId === socket.id) {
                    clearServerSession();
                }
            });
        }

        if (typeof method === 'undefined') {
            return socket;
        }

        switch (method) {
            case 'emit':
                socket.emit(action, params);
                break;
            case 'on':
                if (typeof params !== 'undefined') {
                    socket.on(action, params);
                } else {
                    socket.on(action);
                }
                break;
            case 'disconnect_socket':
                if (typeof socket === 'object' && socket) {
                    socket.disconnect();
                }
                socket = null;
                isConnected = false;
                break;
            default:
                return socket;
        }
    } else {
        if (typeof socket === 'object' && socket) {
            socket.disconnect();
        }
        socket = null;
        isConnected = false;
        console.log('Missing Token.');
    }

}

export default SocketIO;