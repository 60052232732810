import React, {Component} from 'react';
import {select} from 'd3-selection';
import {arc} from 'd3-shape';
import {interpolate} from 'd3-interpolate';
import {isEqual} from 'lodash';

class Label extends Component {
    _isMounted = false;

    arc = arc()
        .innerRadius(60)
        .outerRadius(100)
        .padAngle(.02)
        .padRadius(100)
        .cornerRadius(4);

    state = {
        d: this.props.d,
        label: '',
        center: this.arc.centroid(this.props.d),
        first: true
    }

    labelTween = (oldData, newData, arc) => {
        const copy = { ...oldData };
        return function() {
            const interpolateStartAngle = interpolate(
                    oldData.startAngle,
                    newData.startAngle
                ),
                interpolateEndAngle = interpolate(
                    oldData.endAngle,
                    newData.endAngle
                );

            return function(t) {
                copy.startAngle = interpolateStartAngle(t);
                copy.endAngle = interpolateEndAngle(t);
                let [labelX, labelY] = arc.centroid(copy);
                return `translate(${labelX}, ${labelY})`;
                // return arc(copy);
            };
        };
    }

    d3TakeTheWheel(d) {
        if (this._isMounted) {
            const [labelX, labelY] = this.arc.centroid(d);
            select(this.refs.elem)
                .transition()
                .duration(800)
                .attrTween("transform", this.labelTween(this.state.d, d, this.arc))
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .on("end", () => {
                    if (this._isMounted) {
                        this.setState({
                            center: this.arc.centroid(d),
                            d: d,
                            labelX,
                            labelY
                        })
                    }
                });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // Set new label
        if (this._isMounted) this.setState({
            label: this.props.d.data.value
        })
        this.d3TakeTheWheel(this.props.d);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this._isMounted && prevProps.d !== this.props.d) {
            let newThing = this.arc.centroid(this.props.d);
            let oldThing = this.arc.centroid(prevProps.d);
            let equal = isEqual(newThing, oldThing);

            if (this._isMounted && (prevProps.label !== this.props.label || !equal)) {
                // Set new label
                this.setState({
                    label: this.props.d.data.value
                })
            }
            this.d3TakeTheWheel(this.props.d);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        return (
            <>
            {this._isMounted &&
                <text
                  transform={`translate(${this.state.labelX || 0}, ${this.state.labelY || 0})`}
                  fill="white"
                  fontSize="12"
                  ref='elem'
                  pointerEvents="none"
                >
                  {this.state.label}
                </text>
            }
            </>
        )
    }
};

export default Label;
