import React from 'react';
import AccountSummary from './AccountSummary.js';
import AccountDetailsScreen from './AccountDetailsScreen.js';
import ChangePassword from './ChangePassword.js';
import MultipleAccounts from './MultipleAccounts.js';
import ChangeAccountDetails from './ChangeAccountDetails.js';
import NotFound from '../NotFound.jsx';
import {Switch, Route} from 'react-router-dom';

/**
 *  This Component provides routing logic for the "My Account" section.
 *  @module MyAccount
 *  @see {@link module:AccountSummary AccountSummary}, </br> {@link module:AccountDetailsScreen AccountDetailsScreen} </br> {@link module:ChangeAccountDetails ChangeAccountDetails} </br> {@link module:ChangePassword ChangePassword} </br> {@link module:MultipleAccounts MultipleAccounts}, </br> {@link https://reacttraining.com/react-router/web/guides/quick-start react-router-dom}
 */

function MyAccount() {

    return (
        <>
            <Switch>
                <Route exact path="/business/my-account" component={AccountSummary}/>
                <Route exact path="/business/my-account/account-details" component={AccountDetailsScreen}/>
                <Route path="/business/my-account/change-account-details" component={ChangeAccountDetails} />
                <Route exact path="/business/my-account/password-reset" component={ChangePassword} />
                <Route exact path="/business" component={MultipleAccounts}/>
                <Route component={NotFound} />
            </Switch>
        </>
    )
}

export default MyAccount;
