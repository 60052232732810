import React, {useContext, useEffect} from 'react';
import {AppContext} from '../contexts/PortalContext';
import ReloadToken from './ReloadToken';

function LoaderPage() {
    const context = useContext(AppContext);
    useEffect(() => {
        //const businessUnit = context.state.businessUnit;
        //if(!businessUnit) {
        // comment out for working in all pages
        context.state.dispatch({
            type: 'SET_GLOBAL_LOAD',
            payload: true
        });
        //}

        ReloadToken().then(async (res) => {
            if (res) {
                await context.state.dispatch({
                    type: 'SET_BUSINESS_UNIT',
                    payload: res.business_unit_account
                });

                await context.state.dispatch({
                    type: 'SET_ACTIVE_BILLING_ACCOUNT',
                    payload: res.billingaccountno
                });
            }
            await context.state.dispatch({
                type: 'SET_GLOBAL_LOAD',
                payload: false
            });
        }).catch(err => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.contextConfig]);

    return (
        <></>
    )
}

export default LoaderPage;
