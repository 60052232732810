import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader.jsx';
import {generateKey} from "./utils/utils";
import { fadeAndFall } from './animated.js'
// Material Ui Components
import {Grid, Paper, Button, Divider, makeStyles, Typography} from "@material-ui/core";
// SASS Styles
import style from './styles/Table.module.scss';

const AnimatedGrid = fadeAndFall(Grid);

const useStyles = makeStyles(theme => ({
    footer: {
        background: theme.palette.grey[50],
        padding: '4px 12px',
        display: 'grid',
        justifyContent: 'end'
    },
    background: {
      background: theme.palette.primary.main
    },
    header: {
      background: theme.palette.primary.main,
      width: '100%',
      height: '36px',
    },
    title: {
      color: theme.palette.common.white,
      fontWeight: 600,
      fontSize: '0.9em'
    },
    data: {
      color: theme.palette.grey[400]
    },
  })
);


function NoScrollTable(props)  {
  const classes = useStyles();
  const length = props.rows[0] ? props.rows[0].length : 0;

    const styles = {
        table: {
          overflow: 'auto',
        },
        cellWidth: {
          minWidth: `${props.minCellWidth}px`,
          width: `${1 / length * 100}%`,
        },
        headerWidth: {
          minWidth: `${props.minCellWidth * length}px`,
          textAlign: 'center',
        },
        rowWidth: {
          minWidth: `${props.minCellWidth * length}px`
        },
      };

    return (
      <div className={style.tableContainer}> {/* table container */}
        <div className={`${classes.background}`} style={styles.rowWidth} > {/* table header */}
          <Grid container justify="space-between" alignItems="center" wrap="nowrap" >
            {/* ********** CONDICIONAL RENDER *******/}
            {
              props.headers.length > 1 ? props.headers.map((title, i) => {
                return (
                  <Paper
                    className={` ${classes.header} ${style.cell} `}
                    style={styles.cellWidth}
                    square={true}
                    key={generateKey(title + i)}>
                    <Typography
                      color="textSecondary"
                      className={classes.title}
                    >
                      {title}
                    </Typography>
                  </Paper>
                )
              })
            :
              <Paper
                className={`${style.cell} ${classes.header}`}
                style={styles.headerWidth}>
                <Typography color="textSecondary" className={classes.title}> {props.headers[0]}</Typography>
              </Paper>
            }
          </Grid>
        </div>
        <div style={styles.rowWidth}> {/* table content */}
          {
            props.loading ?
            <Loader height="44px" /> :
            (
              props.rows && props.rows.length > 0 ?
              (
                <>
                  <AnimatedGrid
                    startheight="44px"
                    endheight="216px"
                    item xs={12}>
                    {/* Render only the first 6 results for the Latest Documents Table */}
                    {props.rows.slice(0, props.limit).map((invoice, i) => {
                      return (
                        <div className={style.row} key={generateKey(`${invoice[0]}+${i}`)}>
                          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                            {invoice.map((item, i) => {
                              return (
                                <div
                                  className={`${style.data} ${style.cell}`}
                                  style={styles.cellWidth}
                                  key={generateKey(item + i)}>
                                  {item}
                                </div>
                              )
                            })}
                          </Grid>
                        </div>
                      )
                    })}
                  </AnimatedGrid>
                  <Divider />
                  <div className={classes.footer}> {/* table header */}
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      href={`/#/business/${props.link}`}>
                      More
                    </Button>
                  </div>
                </>
              ) :
              <div style={{padding: '12px 8px', backgroundColor: 'white'}}>
                {`No ${props.dataName} available.`}
              </div>
            )
          }
        </div>
      </div>
    )
}

NoScrollTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  minCellWidth: PropTypes.number,
  link: PropTypes.string
};

export default NoScrollTable
