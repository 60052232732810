// Font Awesome pro config
import {library} from '@fortawesome/fontawesome-svg-core' // Import fa library
import {
    faArrowRight,
    faUserCircle,
    faSignIn,
    faBadgeDollar,
    faFileInvoiceDollar,
    faFileInvoice,
    faFileChartLine,
    faFileCheck,
    faCalculator,
    faCheckCircle as faCheckCircleLight,
    faCreditCardFront as faCreditCardFrontLight,
    faChartPie,
    faChartBar
} from '@fortawesome/pro-light-svg-icons' // Import all fal icons
import {
    faCheckCircle,
    faArrowAltLeft,
    faUserCircle as faUserCircleReg,
    faFileAlt,
    faFileCsv,
    faCreditCardFront,
    faSignOutAlt,
    faBars,
    faSpinner,
    faBuilding,
    faTimes,
    faTimesCircle,
    faQuestionCircle,
    faMobileAlt,
    faDesktop,
    faBrowser,
    faUserSlash
} from '@fortawesome/pro-regular-svg-icons' // Import all far icons
import {
    faArrowAltCircleLeft,
    faMoneyCheckEditAlt,
    faUserShield
} from '@fortawesome/pro-solid-svg-icons' // Import all fas icons
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faChrome,
    faFirefox,
    faEdge,
    faInternetExplorer,
    faOpera,
    faSafari,
    faWindows,
    faLinux,
    faApple
} from '@fortawesome/free-brands-svg-icons'

// Import FontAwesome Individual Icons used in the app.
library.add(faArrowRight,
    faBuilding,
    faUserShield,
    faMoneyCheckEditAlt,
    faSpinner,
    faCheckCircle,
    faTimes,
    faTimesCircle,
    faCheckCircleLight,
    faSignOutAlt,
    faArrowAltLeft,
    faArrowAltCircleLeft,
    faCcVisa,
    faFileCsv,
    faCcMastercard,
    faBars,
    faCcAmex,
    faCcDiscover,
    faUserCircle,
    faUserCircleReg,
    faSignIn,
    faFileAlt,
    faCreditCardFront,
    faCreditCardFrontLight,
    faQuestionCircle,
    faCalculator,
    faFileInvoice,
    faBadgeDollar,
    faFileInvoiceDollar,
    faFileChartLine,
    faFileCheck,
    faChartPie,
    faChartBar,
    faChrome,
    faFirefox,
    faEdge,
    faInternetExplorer,
    faOpera,
    faSafari,
    faWindows,
    faLinux,
    faApple,
    faMobileAlt,
    faDesktop,
    faBrowser,
    faUserSlash
);
