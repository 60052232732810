import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppContext} from '../contexts/PortalContext.js';
import style from './styles/Common.module.scss';
import ErrorBoundary from "./ErrorBoundary";

const Loader = (props) => {

    const context = useContext(AppContext);
    const themePrimary = (context.state.contextConfig.color) ? context.state.contextConfig.color : 'black';

    return (
        <ErrorBoundary>
            <div className={style.loader_container} style={{height: props.height}}>
                <div
                    className={style.loader}
                    style={{
                        color: themePrimary,
                        height: props.height,
                        backgroundColor: props.bg //used to overwrite white background color if needed
                    }}>
                    <FontAwesomeIcon className='fa-spin' icon={['far', 'spinner']}/>
                </div>
            </div>
        </ErrorBoundary>
    )
};

export default Loader;

Loader.propTypes = {
    height: PropTypes.string,
    bg: PropTypes.string,
};
