import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

/**
 * This component renders a modal when a users session has expired.
 * @module TimeModal/SimpleModal
 */

const useStyles = makeStyles(theme => ({
  modalButton: {
    marginTop: '14px'
  },
  paper: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
}));

export default function SimpleModal({closeModal, open = false}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={closeModal}
      >
        <div className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            This session has expired
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            For security purposes you are being logged out.
          </Typography>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={closeModal}
            className={classes.modalButton}
          >
            Close
          </Button>
          <SimpleModal />
        </div>
      </Modal>
    </div>
  );
}

