import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route} from 'react-router-dom'
import './normalize.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { AppContext, ContextProvider } from "./contexts/PortalContext.js";

const render = () => {
  ReactDOM.render(
    <HashRouter>
      <Route path="/" render={ () => (
        <ContextProvider>
          <AppContext.Consumer>
            {
              (values) =>  <App {...values} />
            }
          </AppContext.Consumer>
        </ContextProvider>
      )}/>
    </HashRouter>, document.getElementById('root'));
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
