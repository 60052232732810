import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {catchAxios, clearServerSession} from "./utils/utils";
import SocketIO from "./SocketIO";

function ReloadToken() {
    const authToken = sessionStorage.getItem('token');
    if (authToken) {
        return axios('/auth/refresh', {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        }).then((response) => {
            if (response.data.status && response.data.authToken) {
                sessionStorage.setItem('token', response.data.authToken);
                SocketIO('emit', 'userAction', {
                    current_url: window.location.href,
                });
                return response.data;
            } else {
                clearServerSession();
                return (<Redirect to="/login"/>);
            }
        }).catch((err) => {
            catchAxios(err);
        });
    }
    return new Promise(r => r);
}

export default ReloadToken;