import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import Header from "./Header";
import Footer from './Footer';
import {ErrorMessage, Form, Formik} from "formik";
import * as yup from "yup";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Typography
} from "@material-ui/core";
import { withTheme } from '@material-ui/core/styles';
import {verifyCaptchaToken} from "./utils/utils";
import {ReCaptcha} from "react-recaptcha-v3";
import {Link} from "react-router-dom";
import Loader from "./Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
// SASS Styles
import style from "./styles/Register.module.scss";
import Loading from './Loading';
import ErrorBoundary from "./ErrorBoundary";

/**
 *  This Component renders the change password from for a user that has used that used the password recovery link. If a user requested to reset their password they will be sent a link to this page.  Upon page load, the link will be verified for security and the user will be able to input their new password to reset.  This Component uses {@link https://jaredpalmer.com/formik/docs/api/formik Formik} to handle form input, submission and validation.
 *  @module ChangePasswordRecovery
 *  @see {@link https://jaredpalmer.com/formik/docs/api/formik Formik}, </br> {@link https://www.npmjs.com/package/yup Yup}
 */

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        const {email, token} = this.props.match.params;

        this.state = {
            recaptchaVerified: false,
            email: email,
            token: token,
            isVerified: {
                status: false,
                msg: ''
            },
            isLoading: false,
            dialogOpen: false,
            redirect: false,
        }
    }

    componentDidMount() {
        axios.get(`/password-reset/verify/${this.state.token}/${this.state.email}`)
            .then(data => {
                this.setState({isVerified: {status: data.data.status, msg: data.data.msg}, dialogOpen: true});
            })
            .catch(err => {
                this.setState({isVerified: {status: false, msg: err}, dialogOpen: true});
            })
    }

    handleSubmit = (values) => {
        if (!this.state.recaptchaVerified) {
            this.setState({
                dialogOpen: true,
                isVerified: {
                    status: false,
                    msg: 'Ooops! there was a problem validating the captcha. Please reload and try again.'
                }
            });
        } else {
            this.setState({isLoading: true});
            axios.post('/password-reset/reset/', {
                email: this.state.email,
                recoveryCode: this.state.token,
                password: values.password,
                passwordRepeated: values.passwordRepeated
            })
                .then(data => {
                    this.setState({isLoading: false, isVerified: {status: data.data.status, msg: data.data.msg}});
                  setTimeout(() => {
                    this.setState({redirect: true})
                  }, 3000)
                })
                .catch(err => {
                    this.setState({isLoading: false, isVerified: {status: false, msg: err}});
                });
        }
    };

    handleDialogClose = () => {
        this.setState({dialogOpen: false});
        if (!this.state.isVerified.status) {
            return this.props.history.push('/login');
        }
    };

    // Get recaptcha user token
    verifyCallback = async (recaptchaToken) => { // Here you will get the final recaptchaToken!!!;
        if (recaptchaToken) {
            if (await verifyCaptchaToken(recaptchaToken) === true) {
                this.setState({
                    recaptchaVerified: true,
                });
            } else {
                this.setState({
                    recaptchaVerified: await verifyCaptchaToken(recaptchaToken),
                    error: 'Error verifying CAPTCHA, please try clearing your cache and reloading the page.'
                });
            }
        }
    };

    render() {
        const validationSchema = yup.object().shape({
            password: yup.string()
                .matches(/^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z]).{8,72}$/, {
                    message: 'Minimum 8 characters. Must contain 2 Uppercase Letters, 2 Lowercase Letters, 1 Special Character (Ie. $,#,£,@) and 2' +
                        ' Digits.',
                    excludeEmptyString: true
                })
                .required('A password is required.'),
            passwordRepeated: yup.string()
                .oneOf([yup.ref('password'), null], "Passwords must match") //matches the pwd to the new one
                .required('This field cannot be empty.')
        });

        return (
          <div className={style.container} style={{backgroundColor: this.props.theme.palette.primary.main}}>
            {this.state.redirect && <Redirect to="/login" />}
                <Header/>
                <>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{
                            password: '',
                            passwordRepeated: ''
                        }}
                        onSubmit={this.handleSubmit}
                        render={props => (
                            <Form>
                                <div className={`${style.formContainer} ${style.shadow}`}
                                     style={{overflow: 'hidden'}}>
                                    <h1 className={style.formTitle}>Enter New Password</h1>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="New Password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        margin="normal"
                                        variant="outlined"
                                        style={{gridColumn: '1/8'}}
                                        type="password"
                                    />
                                    <ErrorMessage name="password" component="div" className={style.error}/>
                                    <TextField
                                        id="passwordRepeated"
                                        name="passwordRepeated"
                                        label="Repeat Password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        margin="normal"
                                        variant="outlined"
                                        style={{gridColumn: '1/8'}}
                                        type="password"
                                    />
                                    <ErrorMessage name="passwordRepeated" component="div"
                                                  className={style.error}/>
                                    {this.state.isLoading ?
                                        <Loader />
                                    :
                                        (this.state.isVerified.msg !== '') &&
                                        <div
                                            className={(this.state.isVerified.status) ? style.success : style.error}>{this.state.isVerified.msg}</div>
                                    }
                                    <div className={style.buttonContainer} >
                                    <Link to="/" style={{width: '47%'}}>
                                        <Button variant="contained" color="primary"
                                                style={{maxHeight: '36px', width: '100%'}}>
                                            {<FontAwesomeIcon style={{paddingRight: '4px'}}
                                                              icon={['fas', 'arrow-alt-circle-left']}/>}
                                            BACK
                                        </Button>
                                    </Link>
                                    {(this.state.recaptchaVerified && this.state.isVerified.status && !this.state.isLoading) ?
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            autofocus
                                            style={{maxHeight: '36px', width: '47%'}}
                                        >
                                            Submit
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            style={{maxHeight: '36px', width: '47%'}}
                                            disabled
                                        >
                                            <Loading
                                                loading={true}
                                                loadingText="VERIFYING"
                                                submitText="SUBMIT"
                                            />
                                        </Button>
                                    }
                                  </div>
                                </div>
                            </Form>
                        )}
                    />
                    <ErrorBoundary>
                        <ReCaptcha
                            sitekey={process.env.REACT_APP_RECAPTCHA}
                            action='login'
                            verifyCallback={this.verifyCallback}
                        />
                    </ErrorBoundary>
                <Footer/>
                <ErrorBoundary>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={this.handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            id="alert-dialog-title">{this.state.isVerified.status ? 'Your credentials have been verified.' : 'Sorry we couldn\'t verify your credentials.'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography align="left">{this.state.isVerified.msg}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary" variant="contained">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ErrorBoundary>
                </>
            </div>
        );
    }
}

export default withTheme(ChangePassword);
