import axios from 'axios';
import {catchAxios} from '../../utils/utils';

export const getInitialData = async (dateFrom, dateTo, url) => {
    const authToken = sessionStorage.getItem('token');
    const registrations = axios.post(url, {
        dateFrom: dateFrom,
        dateTo: dateTo
    }, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    });

    return registrations
        .then(res => {
            return res;
        }).catch(err => {
            catchAxios(err);
        })
};

export const getDetails = async (dateFrom, dateTo, limit, offset, businessUnit, url) => {
    const authToken = sessionStorage.getItem('token');

    const details = axios.post(url, {
        dateFrom,
        dateTo,
        businessUnit,
        limit,
        offset,
    }, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    });

    return details
        .then(async (res) => {
            return res;
        })
        .catch(err => {
            catchAxios(err)
        })
};