import React, {Component} from 'react';
import axios from 'axios';
import InvoiceTable from './InvoiceTable.jsx';
import qs from 'qs';
import {formatDate} from '../utils/utils.js';
import {catchAxios} from "../utils/utils";

/**
 * Gets invoice data and then renders InvoiceTable
 * @param {Object} props Loading prop and additional styles
 * @module InvoiceDataContainer
 * @extends Component
 */
class InvoiceDataContainer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        let limit = 0;
        if (props.limit) {
            limit = props.limit;
        }
        this.state = {
            success: '',
            error: '',
            loading: true,
            loadingProforma: true,
            data: [],
            proforma: [],
            limit
        };
    }

    // Get invoice document
    handleSubmit = (file) => {
        const data = {
            fileName: file
        };

        const authToken = sessionStorage.getItem('token');
        if (authToken) {
            // Get and download file
            axios({
                method: 'POST',
                url: '/invoices/document',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${authToken}`
                },
                data: qs.stringify(data), // Recommended by axios
                responseType: 'blob',
            }).then(({data}) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', file);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch((err) => {
                catchAxios(err);
            });
        }
    };

    // Sort invoice date in descending order
    sortData = (data) => {
        data.sort(function (a, b) {
            return new Date(formatDate(b.date)) - new Date(formatDate(a.date));
        })
    };

    // Return appropriate data objects
    buildData = (data) => {
        if (data.length === 0) {
            return data;
        }

        return data.map(e => {
            return {
                date: formatDate(e.date),
                file: e.file,
                invoiceNumber: e.invoiceNumber
            }
        });
    };

    getInvoiceData = (type) => {
        let routePath = 'invoice-data';

        // change the route for Proforma Invoices
        if (type === 'proforma') {
            routePath = 'proforma-data'
        }

        this.setState({
            success: '',
            error: ''
        });
        const authToken = sessionStorage.getItem('token');
        if (authToken) {
            axios({
                method: 'POST',
                data: {limit: this.state.limit},
                url: `/invoices/${routePath}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then((response) => {
                if (this._isMounted) {
                    // Check for correct response format
                    if (response.data && response.data.msg) {
                        if (response.data.status === true && type !== 'proforma') {
                            // Handle response data
                            this.setState({
                                success: response.data.msg,
                                data: response.data.data,
                                loading: false
                            });
                        } else if (response.data.status === true && type === 'proforma') {
                            this.setState({
                                success: response.data.msg,
                                proforma: response.data.data,
                                loadingProforma: false
                            });
                        } else if (response.data.status === false) {
                            this.setState({
                                error: response.data.msg,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({error: 'There was a problem retrieving your invoice data.'});
                    }
                }
            }).catch((err) => {
                this.setState({
                    error: 'There was a problem retrieving your invoice data.',
                    loading: false
                });
                catchAxios(err);
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getInvoiceData();
        this.getInvoiceData('proforma');
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    render() {
        let data = this.buildData(this.state.data);
        let proforma = this.buildData(this.state.proforma);
        const invoicePath = window.location.href.split('/');

        return (
            <>
                <InvoiceTable
                    additionalStyles={this.props.additionalStyles}
                    tableTitle="YOUR LATEST INVOICES"
                    handleSubmit={this.handleSubmit}
                    loading={this.props.loading ? true : this.state.loading}
                    data={data}
                />
                {this.state.proforma.length > 0 && invoicePath.includes('my-invoices') &&
                <InvoiceTable
                    additionalStyles={this.props.additionalStyles}
                    tableTitle="PF INVOICES"
                    handleSubmit={this.handleSubmit}
                    loading={this.props.loadingProforma ? true : this.state.loading}
                    data={proforma}
                />
                }
            </>
        )
    }
}

export default InvoiceDataContainer;
