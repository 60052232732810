import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Formik, Form, ErrorMessage, Field} from 'formik';
import {Link as RouterLink} from 'react-router-dom';
import {FadeIn} from './animated.js'
import TextFieldFormik from './TextFieldFormik'; // Use Formik as a wrapper for material-ui TextField
import ErrorBoundary from "./ErrorBoundary";
import {Button, Link, InputAdornment, IconButton} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import style from './styles/LoginForm.module.scss';
import * as yup from "yup";
import {ReCaptcha} from 'react-recaptcha-v3'
import {AppContext} from '../contexts/PortalContext';
import {verifyCaptchaToken, decodeToken} from "./utils/utils";
import Loading from './Loading.jsx';
import Loader from './Loader';

const AnimatedError = FadeIn(ErrorMessage);

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recaptchaVerified: false,
            error: '',
            showPassword: false
        };
    }

    _handleFormSubmit = (values, actions, context) => {
        if (this.state.recaptchaVerified) {
            this.handleAuth(values, this.state.recaptchaToken, actions, context)
        } else {
            return alert('Please refresh browser and try again.');
        }
    };

    // Get recaptcha user token
    verifyCallback = async (recaptchaToken) => { // Here you will get the final recaptchaToken!!!;
        if (recaptchaToken) {
            if (await verifyCaptchaToken(recaptchaToken) === true) {
                this.setState({
                    recaptchaVerified: true,
                });
            } else {
                this.setState({
                    recaptchaVerified: await verifyCaptchaToken(recaptchaToken),
                    error: 'Error verifying CAPTCHA, please try clearing your cache and reloading the page.'
                });
            }
        }
    };

    // Get and store JSON web token
    handleAuth = (values, recaptchaToken, actions) => {
        // Login request
        const {username, password} = values;
        const data = {
            username: username,
            password: password,
            recaptchaToken
        };

        axios.post('/auth', data).then((response) => {
            if (response.data.status) {
                if (response.data.authToken) {
                    sessionStorage.setItem('token', response.data.authToken);
                    sessionStorage.setItem('session', JSON.stringify({
                        name: response.data.userDisplayName
                    }));

                    const billingaccountno = parseInt(response.data.billingaccountno);
                    if (billingaccountno > 0) {
                        this.context.state.dispatch({
                            type: 'SET_ACTIVE_BILLING_ACCOUNT',
                            payload: billingaccountno,
                        });
                    }

                    // Redirect to correct path based on multiAccount off token
                    if (response.data.multiAccount || response.data.isAdmin) {
                        this.props.history.push('/business');
                    } else {
                        this.props.history.push('/business/my-account');
                    }
                } else {
                    actions.setSubmitting(false);
                    actions.setStatus({error: 'There was an error, refresh the browser and try again.'});
                }
            } else {
                actions.setSubmitting(false);
                actions.setStatus({error: response.data.msg});
            }
        }).catch((err) => {
            actions.setSubmitting(false);
            if (err.response && err.response.status === 401) {
                actions.setStatus({error: 'Invalid user name or password'});
            } else {
                actions.setStatus({error: 'Sorry, there was a problem processing your request.'});
            }
        });
    };

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        // Check if token is expired and then redirect
        if (token) {
            const decodedToken = decodeToken(token);
            const multiAccount = (decodedToken && decodedToken.user && decodedToken.user.multiAccount) ? true : false;

            // Only check if token is expired
            // Token will get checked on all private routes
            // Don't refresh token on login page
            if (decodedToken && decodedToken.exp) {
                const now = new Date();
                // Don't redirect if token is expired
                if (now.getTime() > decodedToken.exp * 1000) {
                    sessionStorage.removeItem('token');
                } else {
                    if (multiAccount) {
                        this.props.history.push('/business');
                    } else {
                        this.props.history.push('/business/my-account');
                    }
                }
            }
        }
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    render() {
        const valid = yup.object().shape({
            username: yup
                .string()
                .email('You must provide a valid email address.')
                .required('A valid email address is required.'),
            password: yup
                .string()
                .required('Password is a required field.')
        });

        return (
            (typeof sessionStorage !== 'object') ?
                <div> This browser is not supported. Please update your browser to continue.</div>
                :
                <div>
                    <AppContext.Consumer>
                        {(context) => (
                            <>
                                <ErrorBoundary>
                                    <Formik
                                        initialValues={{
                                            username: '',
                                            password: '',
                                        }}
                                        validationSchema={valid}
                                        onSubmit={(values, actions) => {
                                            actions.setStatus(null); // Reset status on every submit
                                            this._handleFormSubmit(values, actions, context) // Check reCAPTCHA then submit auth to server
                                        }}
                                        render={({errors, isSubmitting, values, setStatus, status}) => (
                                            <Form className="login-form" style={{gridColumn: '1 / end'}}>
                                                <div className={style.formGroup}>
                                                    <Field
                                                        className={style.loginInputField}
                                                        id="username"
                                                        label="Username"
                                                        name="username"
                                                        autoComplete="username"
                                                        placeholder="Email"
                                                        type='email'
                                                        margin="normal"
                                                        variant="outlined"
                                                        component={TextFieldFormik}
                                                    />
                                                    <AnimatedError name="username" component="div"
                                                                   className={style.error}/>
                                                    <Field
                                                        className={style.loginInputField}
                                                        id="password"
                                                        label="Password"
                                                        name="password"
                                                        autoComplete="current-password"
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        placeholder="Password"
                                                        margin="normal"
                                                        variant="outlined"
                                                        component={TextFieldFormik}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickShowPassword}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                    >
                                                                        {this.state.showPassword ? <VisibilityOff/> :
                                                                            <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <AnimatedError name="password" component="div"
                                                                   className={style.error}/>
                                                    {status && status.error &&
                                                    <div className={style.error}>{status.error}</div>}
                                                    {!this.state.recaptchaVerified &&
                                                    <div className={style.error}>{this.state.error}</div>}
                                                    <div className={style.center}>
                                                        {(!this.state.recaptchaVerified) ?
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                color="primary"
                                                                className={style.button}
                                                                disabled
                                                            >
                                                                <Loading
                                                                    loading={true}
                                                                    loadingText="LOADING"
                                                                    submitText="SUBMIT"
                                                                />
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                color="primary"
                                                                className={style.button}
                                                                disabled={isSubmitting}
                                                            >
                                                                <Loading
                                                                    loading={isSubmitting}
                                                                    loadingText="LOADING"
                                                                    submitText="SUBMIT"
                                                                />
                                                            </Button>
                                                        }
                                                        <RouterLink to="/register">
                                                            <Button
                                                                variant="contained"
                                                                className={style.button}
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Register
                                                            </Button>
                                                        </RouterLink>
                                                        <RouterLink to="/help">
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                className={`${style.button}`}
                                                            >
                                                                Help
                                                            </Button>
                                                        </RouterLink>
                                                    </div>
                                                    <div className="login-info-span">
                                                        {(isSubmitting) ?
                                                            <Loader/>
                                                            :
                                                            <Link
                                                                to="/forgot-password"
                                                                component={RouterLink}
                                                                color={'textPrimary'}
                                                                variant='body1'
                                                            >
                                                                Forgot Username/Password?
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <ReCaptcha
                                        sitekey={process.env.REACT_APP_RECAPTCHA}
                                        action='login'
                                        verifyCallback={this.verifyCallback}
                                    />
                                </ErrorBoundary>
                            </>
                        )}
                    </AppContext.Consumer>
                </div>
        )
    }
}

LoginForm.contextType = AppContext;
export default withRouter(LoginForm);
