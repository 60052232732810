import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const width = 400;
const height = 400;
const margin = {top: 80, right: 40, bottom: 60, left: 70};

class BarChart extends Component {
    state = {
        bars: [],
        getDetails: null,
    };

    xAxis = d3.axisBottom(); // defines the dynamic axis from d3.  call with .scale(xScale)
    yAxis = d3.axisLeft();

    static getDerivedStateFromProps(nextProps, prevState) {
        const {data, title, colors, getDetails} = nextProps;
        if (!data) return {};

        const labels = data.map(d => d.label);
        //const values = data.map(d => d.values);

        const xScale = d3.scaleBand()
            .domain(labels)
            .range([margin.left, width - margin.right])
            .padding(0.2);

        const [, max] = d3.extent(data, d => parseInt(d.value));
        const yScale = d3.scaleLinear()
            .domain([0, max])
            .range([height - margin.bottom, margin.top]);

        const bars = data.map((d, i) => {
            return {
                x: xScale(d.label),
                y: yScale(d.value),
                height: height - yScale(d.value),
                width: xScale.bandwidth(),
                fill: colors[d.label],
                label: labels[i],
            }
        });

        return {bars, xScale, yScale, title, getDetails}
    }

    componentDidMount() {
        this.xAxis.scale(this.state.xScale);
        d3.select(this.refs.xAxis).call(this.xAxis)
        this.yAxis.scale(this.state.yScale);
        d3.select(this.refs.yAxis).call(this.yAxis)
        d3.select(this.refs.bars)
            .selectAll('rect')
            .data(this.state.bars)
            .attr('height', d => (d.height - margin.bottom))
            .transition() // if an attribute is manipulated here, it MUST be removed from the render function.
            .attr('y', d => d.y) // remove property 'y' from all <rect> elements in render... etc.
            .attr('x', d => d.x)
            .attr('height', d => d.height - margin.bottom)
            .attr('fill', d => d.fill)
            .attr('width', d => d.width)
    }

    componentDidUpdate() {

        // no conditional in place because we are not changing state.  d3 is directly manipulating the dom.
        // if code is added where state is changing an infinite loop will be created unless a conditional is place to compare prevState, snapShot, prevProp, etc.
        this.xAxis.scale(this.state.xScale);
        d3.select(this.refs.xAxis).call(this.xAxis)
        this.yAxis.scale(this.state.yScale);
        d3.select(this.refs.yAxis).call(this.yAxis)
        d3.select(this.refs.bars)
            .selectAll('rect')
            .data(this.state.bars)
            .attr('height', d => (d.height - margin.bottom))
            .transition() // if an attribute is manipulated here, it MUST be removed from the render function.
            //.attr('y', d => d.y) // remove property 'y' from all <rect> elements in render... etc.
            .attr('x', d => d.x)
            .attr('height', d => d.height - margin.bottom)
            .attr('fill', d => d.fill)
            .attr('width', d => d.width)
    }

    render() {
        let {getDetails} = this.state;

        return (
            <svg width={width} height={height}>
                <g ref="bars">
                    {this.state.bars.map((d, i) => {
                        //return <rect x={b.x} y={b.y} height={b.height - margin.bottom} width={b.width} fill={b.fill} key ={i} />
                        return <rect key={i} y={d.y} onClick={() => {
                            (getDetails !== null) ? getDetails(d.label) : console.log(i);
                        }}/>
                    })}
                </g>
                <g ref='xAxis' transform={`translate(0, ${height - margin.bottom})`}/>
                <g ref='yAxis' transform={`translate(${margin.left}, 0)`}/>
                <text
                    x="50%"
                    y="50"
                    fill="black"
                    fontSize="22"
                    // dominantBaseLine="middle"  This is generating an error, says it can't be used here.
                    textAnchor="middle"
                >
                    {this.state.title}
                </text>
            </svg>
        )
    }
}

export default BarChart;

//proptypes = title and data
BarChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};
