import React from 'react';
import style from './styles/Common.module.scss';
import ErrorBoundary from "./ErrorBoundary";

const Loading = ({loading, loadingText, submitText}) => {
    if (loading) {
        return (
            <ErrorBoundary>
                <div className={style.loading}>
                    {loadingText}<span className={style.one}>.</span><span className={style.two}>.</span><span
                    className={style.three}>.</span>
                </div>
            </ErrorBoundary>
        )
    } else {
        return (
            <ErrorBoundary>
                <span>{submitText}</span>
            </ErrorBoundary>
        )
    }
};

export default Loading;
