import React, {useContext} from 'react';
import SectionTitle from "../SectionTitle.jsx";
import {AppContext} from "../../contexts/PortalContext";
import {makeStyles} from "@material-ui/core";
import {Typography} from "@material-ui/core";
// SASS Styles
import style from '../styles/HelpSection.module.scss';
import {enterComponent} from '../animated.js'
import ErrorBoundary from "../ErrorBoundary";
import QuickGuideContent from "../QuickGuideContent";
import LoaderPage from "../LoaderPage";

/**
 *  This Component renders a static document that provides the user with a high level overview of the App's primary features. At the bottom of the document, contact information is displayed using the App's context to display information from the correct subsidiary.
 *  @module SimpleGuide
 */

const AnimateDiv = enterComponent('div');

const useStyles = makeStyles(theme => ({
    contactText: {
        textTransform: 'uppercase',
        color: theme.palette.primary.contrastText
    },
    guideContacts: {
        gridColumn: '1/-1',
        margin: '24px auto 0 auto',
        background: theme.palette.primary.main,
        color: theme.palette.grey[100],
        padding: '2vw',
        textAlign: 'center'
    },
    guideContainer: {
        gridColumn: '1/-1',
        width: '90%',
        maxWidth: '1080px',
        margin: '3vh auto',
        background: theme.palette.grey[50],
        padding: 0,
        minHeight: '90vh',
        fontSize: '1.3em',
        boxShadow: '3px 3px 15px #000',
    },
}));

function QuickGuide() {
    const classes = useStyles();
    const context = useContext(AppContext);

    return (
        <>
            <LoaderPage/>
            <div>
                <ErrorBoundary>
                    <SectionTitle variant="h1" style={style.welcome} title="Quick Guide"/>
                </ErrorBoundary>
                <AnimateDiv className={classes.guideContainer}>
                    <ErrorBoundary>
                        <QuickGuideContent/>
                        {/*<div>*/}
                        {/*  <img src={context.state.contextConfig.quickGuide} alt="quick guide" style={{width: '100%'}} />*/}
                        {/*</div>*/}
                    </ErrorBoundary>
                    <div className={classes.guideContacts}>
                        <Typography
                            className={classes.contactText}
                            component="h2"
                        >Contact {context.state.contextConfig.name}
                        </Typography>
                        <hr/>
                        <Typography>email: {context.state.contextConfig.supportEmail}</Typography>
                        <Typography>Phone #: {context.state.contextConfig.portalHelpLine}</Typography>
                    </div>
                </AnimateDiv>
            </div>
        </>
    )
}

export default QuickGuide;
