import React, {useContext} from 'react';
import {AppContext} from '../contexts/PortalContext.js';
import style from './styles/Header.module.scss';
import ErrorBoundary from "./ErrorBoundary";

function Header() {
    const context = useContext(AppContext).state;

    return (
        <ErrorBoundary>
            <div className={style.logo}>
                <a href={context.contextConfig.portalUrl} title={context.contextConfig.name}>
                    <img src={context.contextConfig.logo} alt={context.contextConfig.name}/>
                </a>
            </div>
        </ErrorBoundary>
    )
}

export default Header;
