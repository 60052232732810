import React, {Component} from 'react';
import ErrorBoundary from "./ErrorBoundary";
import TextFieldFormik from './TextFieldFormik'; // Use Formik as a wrapper for material-ui TextField
import {ErrorMessage, Field, Form, Formik} from "formik";
import Header from './Header';
import Footer from './Footer';
import {FadeIn} from './animated.js'
import style from './styles/Register.module.scss';
import axios from 'axios';
import * as yup from 'yup';
import {ReCaptcha} from 'react-recaptcha-v3';
import {verifyCaptchaToken} from "./utils/utils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Grid
} from "@material-ui/core";
import {withTheme} from '@material-ui/styles';

const AnimatedDiv = FadeIn('div');
const AnimatedError = FadeIn(ErrorMessage);

class RegisterUserDetails extends Component {
    constructor(props) {
        super(props);

        const {email, token} = this.props.match.params;

        this.state = {
            isVerified: {
                status: false,
                msg: ''
            },
            email: email,
            token: token,
            dialogOpen: false,
            isLoading: false,
            recaptchaVerified: false,
            userCreated: false,
            checked: false
        };
    }

    componentDidMount() {
        axios.get(`/users/verify/${this.state.token}/${this.state.email}`)
            .then(data => {
                this.setState({isVerified: {status: data.data.status, msg: data.data.msg}, dialogOpen: true});
            })
            .catch(err => {
                this.setState({isVerified: {status: false, msg: err}, dialogOpen: true});
            })
    }

    handleSubmit = (values) => {
        const {email, name, password, confirmPassword} = values;

        if (!this.state.recaptchaVerified) {
            this.setState({
                dialogOpen: true,
                isVerified: {
                    status: false,
                    msg: 'Ooops! there was a problem validating the captcha. Please reload and try again.'
                }
            });
        } else {
            this.setState({isLoading: true});
            axios.post('/users/register', {
                email,
                name,
                password,
                confirmPassword,
                registerCode: this.state.token
            })
                .then(data => {
                    this.setState({
                        isLoading: false,
                        isVerified: {status: data.data.status, msg: data.data.msg},
                        dialogOpen: true,
                        userCreated: data.data.status
                    });
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                        isVerified: {status: false, msg: err},
                        dialogOpen: true,
                        userCreated: false
                    });
                });
        }
    };

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.checked});
    };

    handleDialogClose = () => {
        this.setState({dialogOpen: false});
        if (this.state.userCreated || !this.state.isVerified.status) {
            return this.props.history.push('/login');
        }
    };

    // Get recaptcha user token
    verifyCallback = async (recaptchaToken) => { // Here you will get the final recaptchaToken!!!;
        if (recaptchaToken) {
            if (await verifyCaptchaToken(recaptchaToken) === true) {
                this.setState({
                    recaptchaVerified: true,
                });
            } else {
                this.setState({
                    recaptchaVerified: await verifyCaptchaToken(recaptchaToken),
                    error: 'Error verifying CAPTCHA, please try clearing your cache and reloading the page.'
                });
            }
        }
    };

    render() {
        const {
            handleBlur
        } = this.props;

        const validationSchema = yup.object().shape({
            email: yup.string()
                .email()
                .required(),
            name: yup.string()
                .min(3)
                .required(),
            password: yup.string()
                .matches(/^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z]).{8,72}$/, 'Minimum 8 characters. Must contain 2 Uppercase Letters, 2 Lowercase Letters, 1 Special Character (Ie. $,#,£,@) 2' +
                    ' Digits.')
                .trim('Passwords cannot contain leading or trailing spaces.').strict()
                .required(),
            confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], "Passwords must match")
                .required('Password confirm is required'),
            // terms: yup.bool()
            //     .test(
            //         'terms',
            //         'Please agree to Terms and Conditions',
            //         (value) => {
            //             return this.state.checked === true;
            //         })
            //     .required('Must agree to terms and services')
        });

        return (
            <div className={`${style.container}`} style={{backgroundColor: this.props.theme.palette.primary.main}}>
                <Header/>
                <Formik
                    initialValues={{
                        email: this.state.email,
                        name: '',
                        password: '',
                        confirmPassword: '',
                        // terms: false
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                    render={({errors, isSubmitting, values, setStatus, status}) => (
                        <Form className={`${style.formContainer} ${style.shadow}`}>
                            <Field
                                label="email"
                                id="email"
                                name="email"
                                margin="normal"
                                variant="outlined"
                                onBlur={handleBlur}
                                component={TextFieldFormik}
                                style={{gridColumn: '1/8'}}
                                disabled
                            />
                            <AnimatedError name="email" component="div" className={style.login_error_field}/>
                            <Field
                                name="name"
                                label="name"
                                id="name"
                                margin="normal"
                                variant="outlined"
                                onBlur={handleBlur}
                                component={TextFieldFormik}
                                style={{gridColumn: '1/8'}}
                            />
                            <AnimatedError name="name" component="div" className={style.login_error_field}/>
                            <Field
                                type="password"
                                name="password"
                                label="password"
                                id="password"
                                margin="normal"
                                variant="outlined"
                                onBlur={handleBlur}
                                component={TextFieldFormik}
                                style={{gridColumn: '1/8'}}
                            />
                            <AnimatedError name="password" component="div" className={style.login_error_field}/>
                            <Field
                                type="password"
                                name="confirmPassword"
                                label="confirm password"
                                id="passwordConfirm"
                                margin="normal"
                                variant="outlined"
                                onBlur={handleBlur}
                                component={TextFieldFormik}
                                style={{gridColumn: '1/8'}}
                            />
                            <AnimatedError name="confirmPassword" component="div"
                                           className={style.login_error_field}/>
                            {/*               TODO: Reinstate the checkbox to accept terms if decided that we will use them. */}
                            {/*<label className={style.formField}>*/}
                            {/*    <Checkbox*/}
                            {/*        name="terms"*/}
                            {/*        id="terms"*/}
                            {/*        checked={this.state.checked}*/}
                            {/*        onChange={this.handleChange('checked')}*/}
                            {/*        value="Terms-Checked"*/}
                            {/*        color="primary"*/}
                            {/*        inputProps={{*/}
                            {/*            'aria-label': 'Accept terms and conditions checkbox',*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    /!* TODO add link to WiLine terms of services *!/*/}
                            {/*    By checking this box, I agree with the {`${values.property}`} terms and*/}
                            {/*    conditions.*/}
                            {/*</label>*/}
                            {/*<AnimatedError name="terms" component="div" className={style.login_error_field}/>*/}
                            {(this.state.isVerified.msg !== '') &&
                            <AnimatedDiv
                                className={(this.state.isVerified.status) ? style.login_success_field : style.login_error_field}>{this.state.isVerified.msg}</AnimatedDiv>
                            }
                            {status && status.success &&
                            <AnimatedDiv className={style.login_success_field}>{status.success}</AnimatedDiv>}
                            <Grid container justify="space-between" style={{gridColumn: '1/-1'}}>
                                <Button variant="contained" color="inherit" type="reset"
                                        className={style.button}
                                        style={{width: '48%'}}
                                >Reset</Button>
                                {(this.state.recaptchaVerified && this.state.isVerified.status && !this.state.isLoading) ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{width: '48%'}}
                                    >Submit
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled
                                        style={{width: '48%'}}
                                    >Verifying...
                                    </Button>
                                }
                            </Grid>
                        </Form>
                    )}
                />
                <ReCaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    action='login'
                    verifyCallback={this.verifyCallback}
                />
                <Footer/>
                <ErrorBoundary>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={this.handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            id="alert-dialog-title">{this.state.isVerified.status ? 'Operation Completed Successfully.' : 'Sorry we couldn\'t verify your credentials.'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.isVerified.msg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary" variant="contained">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ErrorBoundary>
            </div>
        );
    }
}

export default withTheme(RegisterUserDetails)
