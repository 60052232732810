import React, {useContext} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppContext} from "../../contexts/PortalContext";
import Loader from '../Loader';

/**
 * This Component renders a modal that appears when the user wants to delete a payment method or select a new payment method as their default autopay.
 * @module CardModal
 * @param {Object} props - open: boolean </br> closeModal: callback function </br>loading: boolean </br>type: string </br>removeCard: callback function </br> setAutoPay: callback function </br> confirmationMsg: string </br> err: string
 * @see {@link https://material-ui.com/api/dialog/ MUI-Dialogue}
 */


const styles = {
    dialogPaper: {
        minHeight: '260px',
        paddingTop: '24px'
    },
};

const Status = (props) => {
    return (
        <DialogContentText
            variant="h6"
            color="primary"
            align="center"
        >
            {props.type === "success" ?
                <FontAwesomeIcon icon={['far', 'check-circle']} size="6x"
                                 style={{color: 'limegreen', display: 'block', margin: '2vh auto'}}/>
                :
                <FontAwesomeIcon icon={['far', 'times-circle']} size="6x"
                                 style={{color: 'red', display: 'block', margin: '2vh auto'}}/>
            }
            {props.msg}
        </DialogContentText>
    )
};

const CardModal = (props) => {
    const context = useContext(AppContext);

    return (
        <Dialog
            open={props.open || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'sm'}
            classes={{paper: props.classes.dialogPaper}}
        >
            {!props.loading &&
            !props.confirmationMsg &&
            !props.err &&
            <DialogTitle id="alert-dialog-title"
                         align='center'>{props.type === "remove" ? "Delete this payment method?" : "Please agree to the terms below."}</DialogTitle>
            }
            <DialogContent>
                {props.loading ?
                    <Loader height="160px"/>
                    : props.confirmationMsg || props.err ?
                        props.confirmationMsg ?
                            <Status type='success' msg={props.confirmationMsg}/>
                            : <Status type='error' msg={props.err}/>
                        : <>
                            <DialogContentText variant="h6" color="primary" align="left">
                                {(props.type === 'remove' &&
                                    <>Please confirm that you really want to delete this payment
                                        method.
                                        <br/><br/>
                                        Note: You cannot remove a payment method set as auto-pay.
                                    </>) ||
                                (props.type === 'autopay' &&
                                    <>{`I hereby authorize ${context.state.contextConfig.name} ("${context.state.contextConfig.company}") to charge my credit card or
                                        bank account for purchases and services made from ${context.state.contextConfig.name}, in accordance with the
                                        ${context.state.contextConfig.company} Customer Agreement and Work Order. This form will be valid only for the
                                        dollar amount and term noted in the Work Order and is subject to the ${context.state.contextConfig.company}
                                        Billing Policy.`}</>) ||
                                (props.type === 'removeAutopay' &&
                                    <>{`I hereby disallow ${context.state.contextConfig.name} ("${context.state.contextConfig.company}") to charge my credit card or
                                        bank account for purchases and services made from ${context.state.contextConfig.name}, in accordance with the
                                        ${context.state.contextConfig.company} Customer Agreement and Work Order.`}</>)
                                }
                            </DialogContentText>
                        </>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.closeModal}
                    variant="contained"
                    disabled={props.loading}
                >
                    Close
                </Button>
                {!props.confirmationMsg &&
                !props.err &&
                <Button
                    onClick={(props.type === 'remove' && props.removeCard) || (props.type === 'autopay' && props.setAutoPay) || (props.type === 'removeAutopay' && props.removeAutoPay)}
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                >
                    {props.type === 'remove' && <>Yes, Delete</>}
                    {props.type === 'autopay' && <>Agree, set as Auto Pay</>}
                    {props.type === 'removeAutopay' && <>Agree, remove as Auto Pay</>}
                </Button>
                }
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(CardModal)
