import React, {Component} from "react";
// Material Ui Components
import {Button, TextField, Grid, FormHelperText} from '@material-ui/core';
import {AppContext} from '../../contexts/PortalContext';
// Forms and  Validation
import {Formik, ErrorMessage, Form} from "formik";
import {FadeIn} from '../animated.js'
import * as yup from "yup";
import axios from 'axios';
import {catchAxios, getSessionStorage} from "../utils/utils";
import style from "../styles/MainLayout.module.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Loading from '../Loading';

/**
 *  This Component renders a {@link https://jaredpalmer.com/formik/docs/api/formik Formik} form used for changing details related to the account billing information. Current billing account information is queried from the API upon component mounting and, once loaded, prefilled into the form.
 *  @module ChangeBillingAddressForm
 *  @param {function } handleChange - updates the component's state to reflect changes entered into the form
 *  @param {function } handleReset - updatess the compoent's state to clear all mutable values.
 *  @param {function } handleSubmit - Sends the form information to the API
 *  @see {@link https://jaredpalmer.com/formik/docs/api/formik Formik}, </br> {@link https://www.npmjs.com/package/yup Yup }
 */
const AnimatedErrorMessage = FadeIn(ErrorMessage);
const AnimatedDiv = FadeIn('div');

const initialState = {
    user: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    country_code: '',
    postal_code: '',
    firstname: '',
    lastname: '',
    telephone: '',
    fax: '',
    cell: '',
    isVerified: {
        status: false,
        msg: ''
    },
    isSubmitting: false,
    isLoading: false,
    changed: false
};

class ChangeBillingAddressForm extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = initialState;
        this.form = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        const authToken = sessionStorage.token;
        const accountNo = this.context.state.activeBillingAccount;
        this.setState({isLoading: true});
        if (authToken) {
            axios({
                method: 'post',
                url: '/profile/details',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
                data: {accountNo}
            }).then(res => {
                if (this._isMounted) {
                    const data = res.data.data;
                    if (res.data.status) {
                        this.setState({
                            user: `${data.detailsData.firstname} ${data.detailsData.lastname}`,
                            ...data.detailsData,
                            email: data.detailsData.addressbook_email
                        });
                    }
                    this.setState({isLoading: false});
                }
            }).catch(err => {
                this.setState({isLoading: false});
                catchAxios(err);
            });
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            changed: true // Checks to make sure the form has changed before submitting
        });
    };

    handleReset = () => {
        // Don't let user reset user name or email
        this.setState({
            ...initialState,
            user: this.state.user,
            email: this.state.email
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Sets billing address values in context if update is successful
    setBillingAddressInContext = (values) => {
        if (!values) {
            return;
        }
        this.context.state.dispatch({
            type: 'SET_CUSTOMER_DETAILS',
            payload: values
        })
    };

    handleSubmit = (values, setSubmitting) => {
        // Don't submit form if user hasn't changed anything
        if (this.state.changed === false) {
            setSubmitting(false);
            this.setState({
                isSubmitting: false,
                isLoading: false,
                isVerified: {
                    status: false,
                    msg: "Form has not been updated. Please make a change before submitting."
                }
            });

            return;
        }

        this.setState({
            isLoading: true,
            isSubmitting: true,
            isVerified: {
                status: false,
                msg: ''
            }
        });

        setSubmitting(true);

        const token = getSessionStorage('token');

        axios({
            method: 'post',
            url: '/profile/update-billing-address',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {
                ...values
            }
        }).then((response) => {
            setSubmitting(false);
            this.setState({
                isSubmitting: false,
                isLoading: false
            });

            if (response.data.status === true) {
                this.setState({
                    isVerified: {
                        status: true,
                        msg: response.data.msg
                    },
                    changed: false
                });
                this.setBillingAddressInContext(values);
            } else if (response.data.status === false) {
                this.setState({
                    isVerified: {
                        status: false,
                        msg: response.data.msg
                    }
                });
            }
        }).catch((err) => {
            setSubmitting(false);
            this.setState({
                isSubmitting: false,
                isVerified: {
                    status: false,
                    msg: 'There was a problem updating your data, please try again.'
                }
            });
            catchAxios(err);
        });
    };

    render() {
        /**
         *  Regular expression for validating a phone number
         */
        const phoneRegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
        /**
         *  Yup validation object for various form fields.
         */
        const formValidation =
            yup.object().shape({
                address: yup
                    .string()
                    .required('The first address line is required.'),
                state: yup
                    .string()
                    .required('A state is required.')
                    .length(2, 'A 2 Letter State is required.'),
                country_code: yup
                    .string()
                    .required('A valid country code is required.')
                    .length(3, 'Country code maximum length is 3 characters.'),
                city: yup
                    .string()
                    .required('A city is required.')
                    .max(24, 'Maximum length 24 characters.'),
                postal_code: yup
                    .string()
                    .max(12, 'Maximum 12 numbers for postal code.'),
                firstname: yup
                    .string()
                    .min(2, 'Minimum length for first name is 2 characters.')
                    .max(40, 'Maximum length for first name is 40 characters.')
                    .required('Your first name is required.'),
                lastname: yup
                    .string(),
                telephone: yup
                    .string()
                    .matches(phoneRegExp, 'Please use a valid phone number.')
                    .min(9, 'You must use a valid phone number.'),
                fax: yup
                    .string()
                    .nullable()
                    .matches(phoneRegExp, 'Please use a valid phone number.')
                    .min(9, 'You must use a valid phone number.')
                    .max(40, 'Maximum size is 40 digits.'),
                cell: yup
                    .string()
                    .nullable()
                    .matches(phoneRegExp, 'Please use a valid phone number.')
                    .min(9, 'You must use a valid phone number.')
                    .max(40, 'Maximum size is 40 digits.'),
            });
        return (
            <Formik
                ref={this.form}
                initialValues={{
                    ...this.state
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setStatus}) => this.handleSubmit(values, setSubmitting, setStatus)}
                validationSchema={formValidation}
                render={({isSubmitting}) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="user"
                                    value={this.state.user || ''}
                                    label="User Name"
                                    name="user"
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                    style={{width: '100%'}}
                                />
                                <FormHelperText>Please contact support to update user name.</FormHelperText>
                                <TextField
                                    id="address"
                                    name="address"
                                    onChange={this.handleChange}
                                    value={this.state.address || ''}
                                    label="Address Line 1"
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="address" component="div" className={style.error}
                                                      style={{width: '100%'}}/>
                                <TextField
                                    id="address2"
                                    name="address2"
                                    onChange={this.handleChange}
                                    value={this.state.address2 || ''}
                                    label="Address Line 2"
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="city"
                                    name="city"
                                    label="City"
                                    onChange={this.handleChange}
                                    value={this.state.city || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="city" component="div" className={style.error}
                                                      style={{width: '100%'}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="state"
                                    name="state"
                                    label="State"
                                    onChange={this.handleChange}
                                    value={this.state.state || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="state" component="div" className={style.error}
                                                      style={{width: '100%'}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="country_code"
                                    label="Country Code"
                                    name="country_code"
                                    onChange={this.handleChange}
                                    value={this.state.country_code || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="country_code" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="postal_code"
                                    label="Post Code"
                                    name="postal_code"
                                    onChange={this.handleChange}
                                    value={this.state.postal_code || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="postal_code" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="firstname"
                                    name="firstname"
                                    onChange={this.handleChange}
                                    value={this.state.firstname || ''}
                                    label="Contact First Name"
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="firstname" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="lastname"
                                    name="lastname"
                                    onChange={this.handleChange}
                                    value={this.state.lastname || ''}
                                    label="Contact Last Name"
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="lastname" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="email"
                                    name="email"
                                    value={this.state.email || ''}
                                    label="email"
                                    margin="normal"
                                    variant="outlined"
                                    disabled
                                    style={{width: '100%'}}
                                />
                                <FormHelperText>Please contact support to update email.</FormHelperText>
                                <AnimatedErrorMessage name="email" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="telephone"
                                    name="telephone"
                                    onChange={this.handleChange}
                                    value={this.state.telephone || ''}
                                    label="Telephone"
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="telephone" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="fax"
                                    label="Fax"
                                    name="fax"
                                    onChange={this.handleChange}
                                    value={this.state.fax || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="fax" component="div" className={style.error}/>
                            </Grid>
                            <Grid item xs={12} md={6} style={{marginBottom: '24px'}}>
                                <TextField
                                    id="cell"
                                    label="Cell Phone"
                                    name="cell"
                                    onChange={this.handleChange}
                                    value={this.state.cell || ''}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                                <AnimatedErrorMessage name="cell" component="div" className={style.error}/>
                            </Grid>
                            {
                                isSubmitting &&
                                <div className={style.changePasswordSpinner}>
                                    <FontAwesomeIcon icon={['far', 'spinner']} className="fa-spin"/>
                                </div>
                            }
                            {this.state.isVerified.msg !== '' &&
                            <Grid item xs={12}>
                                <AnimatedDiv
                                    className={(this.state.isVerified.status && this.state.isVerified.msg !== '') ? style.success : style.error}>
                                    {this.state.isVerified.msg}
                                </AnimatedDiv>
                            </Grid>
                            }
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button
                                    variant="contained"
                                    type="reset"
                                    onClick={this.handleReset}
                                    disabled={isSubmitting || this.state.isLoading}
                                    style={{width: '100%'}}
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting || this.state.isLoading}
                                    style={{width: '100%'}}
                                >
                                    <Loading
                                        loading={isSubmitting || this.state.isLoading}
                                        loadingText={isSubmitting ? "Sending" : "Loading"}
                                        submitText={"Submit"}
                                        style={{width: '100%'}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

ChangeBillingAddressForm.contextType = AppContext;

export default ChangeBillingAddressForm
