import React from "react";
import TextField from "@material-ui/core/TextField";

/**
 * Returns material-ui TextField component with attributes passed in from parent. Used as a solution to allow Material-UI [TextField]{@link https://material-ui.com/components/text-fields/} with Formik
 * @module TextFieldFormik
 */
const MaterialInput = ({
  field: { /* value, */ ...fields },
  form: { touched, errors, ...rest },
  ...props
  }) => {
  return (
    <TextField
      {...props}
      {...fields}
    />
  );
};

export default MaterialInput;
