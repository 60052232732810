import React from 'react'
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core";

const SectionTitle = (props) => {
  return (
    <>
      <Typography className={props.style} variant={props.variant} color="primary" style={{textTransform: 'uppercase', textAlign: 'center'}}>{props.title}</Typography>
    </>
  )
};

export default SectionTitle;

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    style: PropTypes.string
};

