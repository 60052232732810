import React from 'react'

export default function delayUnmounting(Component) {
  return class extends React.Component {

    state = {
      shouldRender: this.props.ismounted
    };



    componentDidUpdate(prevProps) {
      if (prevProps.ismounted && !this.props.ismounted) {
        setTimeout(
          () => this.setState({ shouldRender: false  }),
          this.props.delayTime
        );
      } else if (!prevProps.ismounted && this.props.ismounted) {
        this.setState({ shouldRender: true  });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}
