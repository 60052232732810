import React from 'react';

const Key = (props) => {
    const {x, pie, height, colors, textColor} = props;
    const y = (height / 2);
    const leftPadding = 20;
    const squareHeight = 10;
    const squareWidth = 10;


    return (
        <svg x={x} y={y} height='200' width='200'>
            {
                pie.map((slice, index) => {
                    let keyColor = colors[slice.data.label];
                    const topMargin = index * 30;
                    let newLabel;
                    if (!slice.data.label) {
                        newLabel = 'none';
                    }
                    if (slice.data.label && slice.data.label.includes('null')){
                        newLabel = slice.data.label.replace('null', 'none')
                    }
                    return (
                        <g key={index}>
                            <rect x={leftPadding} y={topMargin} width={squareWidth} height={squareHeight} style={{fill: keyColor}}/>
                            <text
                                transform={`translate(${leftPadding + squareWidth + 4}, ${topMargin + 6})`}
                                alignmentBaseline="middle"
                                fill={textColor}
                                fontSize="12"
                            >
                                {!newLabel ? slice.data.label : newLabel}
                            </text>
                        </g>
                    )
                })
            }
        </svg>
    )
};

export default Key;
