import React, {Component} from 'react';
import {Formik} from 'formik';
import {Button, Grid} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import * as yup from 'yup';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from '../Loading';
import {dateRange} from './validation';

class DateRangeForm extends Component {
    state = {
        dateFrom: new Date((new Date()).valueOf() - 86350989), // Yesterday
        dateTo: new Date(),
        submitting: false,
        activeChart: 'pie',
    };

    handleChange = (type, payload) => {
        this.setState({
            [type]: payload
        });
    };

    render() {
        const {setChart, submit, submitting, valid, activeChart} = this.props; // Parent component handles all state

        return (
            <Formik
                initialValues={{
                    dateFrom: '',
                    dateTo: ''
                }}
                validationSchema={yup.object().shape(dateRange)}
                onSubmit={() => {
                    submit(this.state.dateFrom, this.state.dateTo); // Submit from parent
                }}
            >
                {props => {
                    const {
                        handleSubmit
                    } = props;

                    return (
                        <form onSubmit={handleSubmit} style={{padding: '16px'}}>
                            <Grid container justify="space-evenly">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            disabled={submitting}
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            id="dateFrom-picker"
                                            label="Start Date"
                                            value={this.state.dateFrom}
                                            onChange={(payload) => this.handleChange('dateFrom', payload)}
                                            style={{height: '68px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            disabled={submitting}
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            id="dateTo-picker"
                                            label="End Date"
                                            value={this.state.dateTo}
                                            onChange={(payload) => this.handleChange('dateTo', payload)}
                                            style={{height: '68px'}}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid container justify="center" spacing={4}>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || !valid}
                                    >
                                        <Loading
                                            loading={submitting}
                                            loadingText="LOADING"
                                            submitText='Get Stats'
                                        />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setChart('pie')}
                                        color={activeChart === "pie" ? "secondary" : "primary"}
                                        variant="contained"
                                    >
                                        <FontAwesomeIcon size="2x" icon={['fal', 'chart-pie']}/>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setChart('bar')}
                                        color={activeChart === "bar" ? "secondary" : "primary"}
                                        variant="contained"
                                    >
                                        <FontAwesomeIcon size="2x" icon={['fal', 'chart-bar']}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

export default DateRangeForm;
