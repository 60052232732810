import * as yup from 'yup';

const dateChecker = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export const dateRange = {
    dateFrom: yup
        .string()
        .typeError('Input must be in the format YYYY-MM-DD')
        .matches(dateChecker, 'Date must be in format YYYY-MM-DD'),
    dateTo: yup
        .string()
        .typeError('Input must be in the format YYYY-MM-DD')
        .matches(dateChecker, 'Date must be in format YYYY-MM-DD')
};