import React, {Component} from 'react';
import SectionTitle from "../SectionTitle.jsx";
import Loader from "../Loader";
// Material Ui Components
import {
    Grid,
    Paper,
    Typography,
    Button
} from '@material-ui/core';
//SASS Styles
import style from "../styles/Payments.module.scss";
import {transitionComponent} from '../animated.js'
import errorStyle from '../styles/MainLayout.module.scss';

/**
 *  This Component allows users to review their payment selection before confirming the payment.
 *  @module PaymentReview
 *  @param {Object} props - prevStep: function </br>nextStep: function </br>confirmPayment: function </br>values: Object </br> error: String </br> loading: Boolean
 *  @param {function} continue - Calls nextStep from parent Component
 *  @param {function} goBack - Calls prevStep from parent Component
 *
 *  @see {@link module:MakeAPayment MakeAPAyment}
 */

const PaperTransition = transitionComponent(Paper);

class PaymentReview extends Component {
    // Next Step on the Form
    continue = e => {
        e.preventDefault();
        this.props.nextStep('confirm');
    };
    // Previous Step on the Form
    goBack = e => {
        e.preventDefault();
        this.props.prevStep();
    };


    render() {
        const {values} = this.props;
        const lastFour = (values && values.selectedPaymentMethod && values.selectedPaymentMethod.lastDigitsCC) ? values.selectedPaymentMethod.lastDigitsCC : '';
        const cardType = (values && values.selectedPaymentMethod && values.selectedPaymentMethod.payType) ? values.selectedPaymentMethod.payType : '';
        const inOrOut = !this.props.ismounted ? 'bounceOut' : 'bounceIn';
        return (
            <>
                <SectionTitle variant="h1" style={style.welcome} title="Confirm Your Payment Details"/>
                <Grid container justify="center">
                    <Grid item xs={12} xl={10}>
                        <PaperTransition square={true} elevation={5} className={style.paymentCard} ismounted={inOrOut}>
                            <div style={{padding: '12px', fontSize: '17px', lineHeight: '20px'}}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className={style.tableLabel}><Typography>Amount Due:</Typography></td>
                                        <td align="right" width="60%" className={style.amount}><Typography
                                            color="primary">{`$${values.amountDue}`}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td className={style.tableLabel}>Pay Amount:</td>
                                        <td align="right" width="60%" className={style.amount}><Typography
                                            color="primary">{`$${values.amountPayable}`}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td className={style.tableLabel}>Pay Method:</td>
                                        <td align="right" width="60%" className={style.amount}><Typography
                                            color="primary">{cardType}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td className={style.tableLabel}>Account/Card Ending In:</td>
                                        <td align="right" width="60%" className={style.amount}><Typography
                                            color="primary">{lastFour}</Typography></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{display: 'block', margin: '0 auto'}}>
                                {!this.props.loading ?
                                    <Typography color="primary" variant="h4" style={{textAlign: 'center'}}>Are the
                                        values above correct?</Typography>
                                    :
                                    <>
                                        <Loader/>
                                        <div style={{textAlign: 'center', marginTop: '1vh'}}>
                                            <Typography variant="h4" color="primary" gutterBottom>Processing Your
                                                Payment</Typography>
                                            <Typography variant="body2" color="secondary">Please do not close this
                                                window.</Typography>
                                        </div>
                                    </>
                                }
                                <div className={style.buttonContainer}>
                                    <Button
                                        variant="contained"
                                        onClick={this.goBack}
                                        size="medium"
                                        disabled={this.props.loading}
                                        style={{width: '48%'}}
                                    >Go Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.continue}
                                        size="medium"
                                        style={{width: '48%'}}
                                        disabled={this.props.loading || this.props.error !== ''}
                                    >{this.props.loading ? 'Loading' : 'Yes'}
                                    </Button>
                                </div>
                                {this.props.error && <p className={errorStyle.error}>{this.props.error}</p>}
                            </div>
                        </PaperTransition>
                    </Grid>
                </Grid>
            </>
        )
    }
}


export default PaymentReview;
