import React, {Component} from 'react';
import {AppContext} from '../../contexts/PortalContext.js';
import SectionTitle from '../SectionTitle.jsx';
import CreditCards from './CreditCards'
import BankAccount from './BankAccount.jsx';
import PropTypes from 'prop-types';
import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl, Typography,
} from "@material-ui/core";
// SASS Styles
import style from '../styles/Payments.module.scss';
import ErrorBoundary from "../ErrorBoundary";
import LoaderPage from "../LoaderPage";

/**
 * This Component renders a form with two radio button options that allow the user to add a payment method.  Depending on the users selection they can either choose to view the {@link module:BankAccount BankAccount} form or {@link module:CreditCards CreditCards} form.  The default form is CreditCards.
 * @module AddNewPaymentMethod
 * @extends Component
 *
 * @see {@link module:BankAccount BankAccount}, </br>{@link module:CreditCards CreditCards}, </br>{@link module:ErrorBoundary ErrorBoundary}, </br>{@link module:SectionTitle SectionTitle}, </br>{@link https://material-ui.com/guides/api/ Material UI Components}
 */

class AddNewPaymentMethod extends Component {
    state = {
        paymentMethod: 'credit',
    };

    handleChange = event => {
        if (event.target.name === 'paymentMethod') {
            this.setState({[event.target.name]: event.target.value});
        }
    };

    render() {

        return (
            <>
                <LoaderPage/>
                <ErrorBoundary>
                    <SectionTitle variant="h1" style={style.welcome} title="Add New Payment Method"/>
                </ErrorBoundary>
                <Grid container spacing={0} style={{padding: '2vw'}}>
                    <Grid item xs={12} xl style={{marginBottom: "80px"}}>
                        <ErrorBoundary>
                            <form className={style.formContainer} noValidate autoComplete="off">
                                <div>
                                    {/* Radio Buttons */}
                                    <FormControl component="fieldset">
                                        <Typography variant="h4" color="primary"
                                                    style={{textAlign: 'center', width: '100%'}}>Payment
                                            Method</Typography>
                                        <RadioGroup
                                            aria-label="Payment Method"
                                            name="paymentMethod"
                                            value={this.state.method}
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel value="credit" control={<Radio color="secondary"
                                                                                             checked={this.state.paymentMethod === 'credit' ? true : false}/>}
                                                              label="Credit Card"/>
                                            <FormControlLabel value="account" control={<Radio color="secondary"/>}
                                                              label="Bank Account"/>
                                        </RadioGroup>
                                    </FormControl>

                                    {/* Credit Card Text input fields */}
                                    {this.state.paymentMethod === 'credit'
                                        ? <CreditCards accNo={this.context.state.activeBillingAccount}/>
                                        : <BankAccount accNo={this.context.state.activeBillingAccount}/>
                                    }
                                </div>
                            </form>
                        </ErrorBoundary>
                    </Grid>
                </Grid>
            </>
        );
    }
}

AddNewPaymentMethod.contextType = AppContext;
export default AddNewPaymentMethod;

AddNewPaymentMethod.propTypes = {
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    isValidating: PropTypes.bool,
    initialValues: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
};
