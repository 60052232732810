// import stephouseLogo from '../assets/img/stephouse.svg';
import stephouseWhite from '../assets/img/stephouse-white.svg';
import stephouseQuickGuide from '../assets/img/StephouseQuickGuide.png';
import wilineLogo from '../assets/img/wiline.svg';
import wilineQuickGuide from '../assets/img/WiLineQuickGuide.png';

/**
 *  Default configuration object for Wiline context
 *  @type {Object}
 */
//@TODO check witch objects values is not in use and remove.
export const wiline = {
    //footer: 'V1.9.3p1 &nbsp;&copy;{props.name} (2006-{newDate}) All rights reserved.&nbsp;',
    contextConfig: {
        //id: 0,
        company: 'WiLine',
        name: 'WiLine Networks, Inc.',
        contact: '',
        address: {
            street: '104 Carnegie Center',
            suite: 'Suite 201',
            city: 'Princeton',
            state_long: 'New Jersey',
            state_short: 'NJ',
            zip: '08540'
        },
        phone: '1-888-494-5463',
        email: 'info@wiline.com',
        salesEmail: 'sales@wiline.com',
        supportEmail: 'support@wiline.com',
        billingEmail: 'billing@wiline.com',
        portalEmail: 'support@wiline.com',
        portalUrl: 'https://my.wiline.com',
        portalHelpLine: '650-523-5494',
        logo: wilineLogo,
        quickGuide: wilineQuickGuide,
        //alt: 'Wiline Networks Logo',
        color: '#074481'
    }
};

/**
 *  Default configuration object for Stephouse context
 *  @type {Object}
 */
export const stephouse = {
    //footer: '',
    contextConfig: {
        //id: 1,
        company: 'Stephouse',
        name: 'Stephouse Networks, Inc.',
        contact: '',
        address: {
            street: '921 SW Washington Street',
            suite: `#224`,
            city: 'Portland',
            state_long: 'Oregon',
            state_short: 'OR',
            zip: '97205'
        },
        phone: '',
        email: 'billing@stephouse.net',
        salesEmail: 'billing@stephouse.net',
        supportEmail: 'billing@stephouse.net',
        billingEmail: 'billing@stephouse.net',
        portalEmail: 'billing@stephouse.net',
        portalUrl: 'https://my.stephouse.net',
        portalHelpLine: '503-548-2000',
        logo: stephouseWhite,
        quickGuide: stephouseQuickGuide,
        //alt: "Stephouse company logo",
        color: '#29abe2',
    }
};
