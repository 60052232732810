import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
        console.log(error)
        console.error(errorInfo)
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div style={{padding: '3vh 2vw', color: '#FF8888'}}>
                    <h2 style={{color: '#FF8888'}}>Oops! Something went wrong!</h2>
                    <details style={{whiteSpace: 'pre-wrap'}}>
                        <p>{this.state.error.toString()}</p>
                    </details>

                    {/* FOR DEVELOPMENT PURPOSES */}
                    {/*<details style={{ whiteSpace: 'pre-wrap' }}>*/}
                    {/*    <p style={{color: 'red'}}>{this.state.error && this.state.error.toString()}</p>*/}
                    {/*</details>*/}
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;