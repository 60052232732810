import React from 'react'
// SASS Styles
import style from '../styles/Payments.module.scss'

/**
 *  Returns a Component that renders a row for the latest invoices table
 *  @module InvoiceRow
 *
 */

function InvoiceRow({date, file, handleSubmit, invoiceNumber}) {
  return (
    <div className={`${style.row} ${style.invoice_row}`} onClick={() => handleSubmit(file)}>
      <div className={style.date}><strong>Invoice #{invoiceNumber}</strong> - {date}</div>
      <div className={style.file}>{file}</div>
    </div>
  )
}

export default InvoiceRow

