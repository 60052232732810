import React from 'react';
import {Link} from 'react-router-dom'
import {Formik, ErrorMessage} from "formik";
import {FadeIn, transitionComponent} from '../animated.js'
import * as yup from 'yup';
import SectionTitle from "../SectionTitle.jsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
// Material UI components
import {
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Grid,
    Paper,
    Button,
    Typography,
    FormHelperText
} from "@material-ui/core";
import {isExpired, checkAchPayment} from '../utils/paymentUtils.js'
import {catchAxios} from '../utils/utils.js';
// SASS Styles
import style from "../styles/Payments.module.scss";

/**
 *  This component gets the details required to make a transaction.  It displays the current balance on a users account, and asks the user for the amount they would like to pay in addition to the payment method they would like to use.  The user is also given a choice to redirect to another page to add a new payment method. </br></br>
 *  This component uses a {@link https://jaredpalmer.com/formik/docs/api/formik Formik} form to accept and handle user input.
 *  @module PaymentDetails
 *
 *  @see {@link https://jaredpalmer.com/formik/docs/api/formik Formik}
 *
 */

const FadeInError = FadeIn(ErrorMessage);
const FadeInSpan = FadeIn('span');
const PaperTransition = transitionComponent(Paper);

/**
 * Function that returns a jsx component with a properly formatted number.
 * @params {Object} props - takes the props passed into the PaymentDetails component
 * @return {component}
 */
function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            isNumericString={true}
            thousandSeparator
            allowNegative={false}
            prefix="$"
        />
    );
}

function buildCards(data) {
    if (data && data.paymentMethods) {
        return data.paymentMethods.map((card, i) => {
            const lastFour = (card.methodType) === 'AHC' ? card.lastDigitsACH : card.lastDigitsCC
            const validCards = isExpired(card.expireMonth, card.expireYear) ? '' // if card is expired do not return as a valid payment option.
                : <MenuItem value={card} key={`${lastFour}-${i}`}
                >
                    {card.payType} - ending in {lastFour} - {card.nameOnAccount}
                </MenuItem>
            return validCards;
        })
    } else {
        return null;

    }
}

const PaymentDetails = (props) => {
    const {
        data,
        submitPayment,
        loading,
        isSubmitting,
        addPaymentStatus // Exists only if user redirected from add payment page
    } = props;

    const amountDue = (loading) ?
        <span style={{fontSize: '24px', marginLeft: '8px'}}><FontAwesomeIcon icon={['far', 'spinner']}
                                                                             className="fa-spin"/></span> :
        <FadeInSpan>{`$${data.amountDue}`}</FadeInSpan>;
    const buttonTxt = (loading || isSubmitting) ? 'LOADING' : 'SUBMIT';

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    const checkPayment = (values) => {
        let errors = {};
        if (values.selectedPaymentMethod.payMethod == 'ACH') {
            //console.log('Paying with ACH...');
            return checkAchPayment(values.selectedPaymentMethod.pay_account_id, values.amountPayable)
            .then(res => {
                if (!res.data.status) {
                    errors.amountPayable = res.data.msg;
                    throw errors;
                }
            })
        }
        
    }

    const inOrOut = !props.ismounted ? 'bounceOut' : 'bounceIn'; //conditionally pass the animation name into styled wrapper
    return (
        <>
            <SectionTitle variant="h1" style={style.welcome} title="Make a Payment"/>
            <Grid container justify="center" spacing={0}>
                <Grid item xs={12} xl={10}>
                    <PaperTransition square={true} elevation={5} className={style.paymentCard} ismounted={inOrOut}>
                        <Typography variant="h4" color="primary" style={{marginBottom: '2vh'}}>Amount
                            Due: {amountDue}</Typography>
                        <Formik
                            initialValues={{
                                amountPayable: '',
                                selectedPaymentMethod: ''
                            }}
                            validate={checkPayment}
                            validationSchema={yup.object().shape({
                                amountPayable: yup
                                    .number()
                                    .typeError('Amount payable must be a number.')
                                    .positive()
                                    .moreThan(0, 'Payment amount must be greater than zero.')
                                    .lessThan(10000, 'Payment amount must be less than $10,000.')
                                    .required('You must choose a payment amount.'),
                                selectedPaymentMethod: yup
                                    .string()
                                    .required('You must select a payment method.')
                            })}
                            // submit data
                            onSubmit={(values, {setSubmitting, setStatus, props}) => {
                                submitPayment(values);
                                setSubmitting(true);
                            }}
                        >
                            {props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            fullWidth
                                            id="amountPayable"
                                            name="amountPayable"
                                            variant="outlined"
                                            label="Amount Payable"
                                            className="payment-field"
                                            value={values.amountPayable}
                                            onChange={handleChange('amountPayable')}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                        <FormControl fullWidth={true} component="div">
                                            <InputLabel htmlFor="pay-method">Payment Method</InputLabel>
                                            <Select
                                                value={values.selectedPaymentMethod}
                                                onChange={handleChange}
                                                disabled={loading || (data &&
                                                    data.paymentMethods &&
                                                    data.paymentMethods.length < 1)
                                                }
                                                inputProps={{
                                                    name: 'selectedPaymentMethod',
                                                    id: 'pay-method',
                                                }}
                                            >
                                                {
                                                    buildCards(data)
                                                }

                                            </Select>
                                            {
                                                !loading &&
                                                data &&
                                                data.paymentMethods &&
                                                data.paymentMethods.length < 1 &&
                                                <FormHelperText>Please add a payment method.</FormHelperText>
                                            }
                                        </FormControl>
                                        <div className={style.button}>
                                            <Link to='/business/payments/add-new-card'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    style={{width: '100%'}}
                                                >Add Card
                                                </Button></Link>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || loading}
                                                style={{width: '100%'}}
                                            >{buttonTxt}
                                            </Button>
                                        </div>
                                        <FadeInError
                                            name="amountPayable"
                                            component="div"
                                            className={style.error}
                                        />
                                        <FadeInError
                                            name="selectedPaymentMethod"
                                            component="div"
                                            className={style.error}
                                        />
                                        {
                                            addPaymentStatus &&
                                            <div className={style.success}>{addPaymentStatus}</div>
                                        }
                                    </form>
                                )
                            }}
                        </Formik>
                    </PaperTransition>
                </Grid>
            </Grid>
        </>
    );
};

export default PaymentDetails;
