import React from 'react';
import SectionTitle from "../SectionTitle.jsx";
import {enterComponent} from '../animated.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CSVLink} from "react-csv";
import axios from 'axios';
import Loader from '../Loader.jsx';
import {
    InputLabel,
    Button,
    Radio,
    Select,
    MenuItem,
    FormControl,
    Grid,
    Paper
} from '@material-ui/core';

//Forms and Validation
import * as yup from 'yup';
import {withFormik} from "formik";
import {FadeIn} from '../animated.js'
// SASS Styles
import style from '../styles/AccountSummary.module.scss';
import ErrorBoundary from "../ErrorBoundary";
import {catchAxios} from "../utils/utils";
import LoaderPage from "../LoaderPage";

const AnimateGrid = enterComponent(Grid);
const FadeInDiv = FadeIn('div');

/**
 * CallDetailRecord allows users to download detailed call records by date or invoice. User Data is available in csv file format.
 * @param {Object} props All props passed in by [withFormik]{@link https://jaredpalmer.com/formik/docs/api/withformik#the-formikbag} from the formik bag.
 * @module CallDetailRecord
 */
function CallDetailRecord(props) {
    // Get available dates for last 12 months
    const buildDates = () => {
        const monthName = [
            "January", "February", "March",
            "April", "May", "June",
            "July", "August", "September",
            "October", "November", "December"
        ];

        const d = new Date();
        return monthName.map((e, i) => {
            let item = {
                value: '',
                label: ''
            };

            // Format for label
            item.label = `${monthName[d.getMonth()]}, ${d.getFullYear()}`;

            // Format for server
            item.value = `${d.getFullYear()}-${d.getMonth() + 1}-01`
            d.setMonth(d.getMonth() - 1);

            return item;
        });
    };

    const months = buildDates();

    const {
        values,
        errors,
        status,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        setStatus
    } = props;

    return (
        <>
            <LoaderPage/>
            <ErrorBoundary>
                <SectionTitle variant="h1" style={style.welcome} title="Call Detail Record"/>
            </ErrorBoundary>
            <Grid className={style.callContainer} container spacing={0} justify="center">
                <AnimateGrid item xs={12} sm={10} lg={8} xl={6}>
                    <Paper style={{padding: '2vw 3vw', marginTop: '2vh', margin: '20px'}} className={style.shadow}>
                        <form className={style.cdrForm} onSubmit={handleSubmit}>
                            <label>
                                <Radio
                                    id="invoice"
                                    label="Invoice"
                                    value="invoice"
                                    onBlur={handleBlur}
                                    checked={values.type === 'invoice'}
                                    onChange={() => setFieldValue('type', 'invoice')}
                                    color="default"
                                    style={{gridColumn: '1/3'}}
                                />
                                Calls by Invoice
                            </label>
                            <label>
                                <Radio
                                    id="month"
                                    label="Month"
                                    value="month"
                                    checked={values.type === 'month'}
                                    onChange={() => setFieldValue('type', 'month')}
                                    onBlur={handleBlur}
                                    color="default"
                                    style={{gridColumn: '4/end'}}
                                />
                                Calls by Calendar Month
                            </label>
                            <ErrorBoundary>
                                <FormControl style={{gridColumn: '1/end'}}>
                                    <InputLabel htmlFor="age-simple">Select Month</InputLabel>
                                    <Select
                                        value={values.chosenMonth}
                                        onChange={(e) => {
                                            setStatus({data: []});
                                            handleChange(e);
                                        }}
                                        inputProps={{
                                            name: 'chosenMonth',
                                            id: 'month-simple',
                                        }}
                                    >
                                        {
                                            months.map(month => (
                                                <MenuItem
                                                    key={month.value}
                                                    value={month.value}
                                                >
                                                    {month.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </ErrorBoundary>
                            <div className={style.cdrButtons}>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={isSubmitting}
                                    type="reset"
                                    onClick={handleReset}
                                    style={{width: '47%'}}
                                >
                                    clear
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    style={{width: '47%'}}
                                >
                                    {
                                        isSubmitting ?
                                            'Loading' :
                                            'Submit'
                                    }
                                </Button>
                            </div>
                            {
                                // Download link if response file exists
                                status &&
                                status.response &&
                                status.data &&
                                status.data.length > 0 &&
                                <FadeInDiv className={style.csv}>

                                    <div className={style.rdyMsg}>Your calls are ready.</div>
                                    <ErrorBoundary>
                                        <CSVLink
                                            data={status.data}
                                            filename={`${values.chosenMonth}.csv`}
                                            className={style.downloadButton}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{width: '100%'}}
                                            >
                                                Download Now&nbsp;<FontAwesomeIcon icon={['far', 'file-csv']}/>
                                            </Button>
                                        </CSVLink>
                                    </ErrorBoundary>
                                </FadeInDiv>
                            }
                            <div className={style.errorContainer}>
                                {
                                    status &&
                                    status.error &&
                                    <FadeInDiv className={style.error}>{status.error}</FadeInDiv>
                                }
                                {
                                    errors.chosenMonth &&
                                    <FadeInDiv className={style.error}>{errors.chosenMonth}</FadeInDiv>
                                }
                            </div>
                        </form>
                        {
                            // Loading icon
                            isSubmitting &&
                            (
                                <ErrorBoundary>
                                    <Loader/>
                                </ErrorBoundary>
                            )
                        }
                    </Paper>
                </AnimateGrid>
            </Grid>
        </>
    )
}

const FormikRegisterForm = withFormik({
    // Set initial form values
    mapPropsToValues: (props) => {
        let initData = {
            chosenMonth: '',
            type: 'invoice'  // selects invoice by default
        };

        return initData;
    },
    validationSchema: yup.object().shape({
        chosenMonth: yup
            .string()
            .required('You must select a month.'),
        type: yup
            .string()
            .required('You must select if you want your calls grouped by invoice or by calendar month.')
    }),
    // Save csv data
    handleResponse(response, setStatus) {
        setStatus({
            data: response.data.data,
            response: true,
            loading: false
        })
    },
    handleSubmit(values, {setSubmitting, setStatus}) {
        setSubmitting(true);
        setStatus({
            success: '',
            error: ''
        });

        const authToken = sessionStorage.getItem('token');

        axios.post('/invoices/calls', {
            monthYearDate: values.chosenMonth,
            type: values.type
        }, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }).then((response) => {
            setSubmitting(false);

            // Check for correct response format
            if (response.data && response.data.msg) {
                if (response.data.status === true) {
                    setStatus({success: response.data.msg});
                    // Handle response data
                    this.handleResponse(response, setStatus);
                } else if (response.data.status === false) {
                    setStatus({error: response.data.msg});
                }
            } else {
                setStatus({error: 'There was a problem retrieving your call data.'});
            }
        }).catch((err) => {
            setSubmitting(false);
            setStatus({error: 'There was a problem retrieving your call data.'});
            catchAxios(err);
        });
    }
})(CallDetailRecord);

export default FormikRegisterForm;
