import React from 'react';
import {Link} from "react-router-dom";
import LoaderPage from "../LoaderPage";

function HelpMenu(props) {
    return (
        <>
            <LoaderPage/>
            <div className="section-container">
                <Link to='quick-guide' style={{textDecoration: 'none'}}/>
                <Link to='user-guide' style={{textDecoration: 'none'}}/>
                <Link to='contact-us' style={{textDecoration: 'none'}}/>
            </div>
        </>
    )
}

export default HelpMenu;

