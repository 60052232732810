import React, {useState, useEffect} from 'react';
import Header from './Header.js';
import {Grid, Paper, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom';
import style from './styles/NotFound.module.scss';

const NotFound = (props) => {

    let timer = 10;
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect( () => {countDown() }, []);
    const [time, setTime] = useState(timer);

    // Redirects the user in ten seconds.
    const countDown = () => {
        if (timer > 0) {
            timer--;
            setTime(timer);
            setTimeout(() => {
                countDown();
                }, 1000);
        } else {
            return props.history.push('/');
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper square={true} elevation={5} className={style.containerNF}>
                    <Header/>
                    <div className={style.mainContainer}>
                        <h2 className={style.header2}>ERROR 404</h2>
                        <h1 className={style.header1}>PAGE NOT FOUND</h1>
                        <div>
                            <p className={style.text}>The Page you are looking for either does not exist, or an error
                                occurred on the way there.
                                Go back, or go to&nbsp;
                                <Link className={style.link} to='/business/my-account'>
                                    <b>your account</b>
                                </Link>&nbsp;and try another direction.
                            </p>
                            <Typography component="h2" color="secondary">You will be redirected to the home page in {time} seconds.</Typography>
                        </div>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
};

export default NotFound;
