import React, {Component} from 'react'
import {pie} from 'd3-shape';
import Key from './Key.js';
import Slice from './Slice.js';
import Label from './Label.js';
import {generateKey} from '../../utils/utils.js';

const height = 400;
const width = 410;
const radius = width / 4;
const edge = (height / 2) + radius; // Right edge of the pie chart
const titleY = (height / 2 - radius) / 2;

class FancyPie extends Component {
    _isMounted = false;

    state = {
        data: this.props.data,
        title: this.props.title,
        colors: this.props.colors,
        getDetails: this.props.getDetails,
        pieArr: pie().value((d) => d.value)(this.props.data),
    }

    componentDidMount() {
        this._isMounted = true;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {data, title, colors, getDetails} = nextProps;

        // TOD: fix this maybe? Can't do a deep comparison of an array of objects
        // because of performance reasons
        if (true) {
            return {
                data,
                title,
                colors,
                getDetails,
                pieArr: pie().value((d) => d.value)(data)
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({unmounting: true})
    }


    render() {
        return (
            <svg height={height} width={width} style={{backgroundColor: ''}}>
              <text
                x='50%'
                y={titleY}
                fill="black"
                // fontFamily="sans-serif"
                fontSize="20"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {this.state.title}
              </text>
              <g transform={`translate(${width / 2},${height / 2})`}>
                  {this._isMounted && this.state.pieArr.map((d, i) => (
                     <Slice
                         d={d}
                         index={i}
                         key={generateKey(i)}
                         color={this.state.colors[d.data.label]}
                         getDetails={this.state.getDetails}
                     />
                  ))}
                {this._isMounted && this.state.pieArr.map((d, i) => (
                    <Label
                        d={d}
                        key={i}
                        colors={this.state.colors}
                        label={d.data.value}
                        pointer-events="none"
                    />
                ))}
              </g>
              <Key
                  pie={this.state.pieArr}
                  x={edge - 10}
                  height={height}
                  width={width}
                  radius={radius}
                  colors={this.state.colors}
                  textColor='black'
              />
            </svg>
        )
    }
}

export default FancyPie
