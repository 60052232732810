import React, {Component} from 'react';
import {AppContext} from './contexts/PortalContext.js';
import {Route, Redirect, Switch} from "react-router-dom";
import './normalize.css';
import './App.css';
import RefreshScreen from './Components/RefreshScreen';

//import style from './Components/styles/MainLayout.module.scss';
import {ThemeProvider} from '@material-ui/styles';

// Route Components
import Login from './Components/Login';
import RegisterNewUser from './Components/RegisterNewUser';
import RegisterUserDetails from './Components/RegisterUserDetails';
import ForgotPassword from './Components/ForgotPassword';
import ChangePassword from './Components/ChangePassword';
import Help from './Components/Help';
import YourBusinessAccount from './Components/YourBusinessAccount';
import {WiLineTheme, StephouseTheme, DefaultTheme} from './MuiThemes.js';
import PrivateRoute from './Components/auth/PrivateRoute.js';
import NotFound from './Components/NotFound.jsx';
import './icons.js'; // Import individual icons
import {loadReCaptcha} from 'react-recaptcha-v3';
import GlobalModal from "./Components/GlobalModal"; // RECAPTCHA V3

import AdminTools from './Components/admin/adminTools';

/**
 * Function to get the site type using the url
 * @return string   WNI || SNI || empty
 */
export function siteType() {
    const hostname = window.location.hostname;
    const WNIurls = (process.env.REACT_APP_WILINE_HOSTNAME_ALLOW).split(',');
    const SNIurls = (process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW).split(',');

    if (WNIurls.includes(hostname)) {
        return 'WNI';
    } else if (SNIurls.includes(hostname)) {
        return 'SNI';
    }
    return '';
}

export function siteTypeByBusinessUnit(business_unit) {
    let res = '';
    const url = window.location.href;
    if (business_unit && typeof business_unit !== undefined && (business_unit === 'SNI' || business_unit === 'WNI')) {
        res = business_unit;
    } else if (url && url !== undefined) { // if no business_unit is defined, pull from the url
        res = siteType();
    }
    return res;
}


class App extends Component {
    state = {
        loggedIn: false
    };

    componentDidMount() {

        loadReCaptcha(process.env.REACT_APP_RECAPTCHA);
        let title = '';
        let faviconImg = '';
        switch (siteType()) {
            case 'SNI':
                title = 'Customer Portal';
                faviconImg = '/assets/favicon/sni.ico';
                break;
            case 'WNI':
                title = 'Customer Portal';
                faviconImg = '/assets/favicon/wni.ico';
                break;
            default:
                title = 'Customer Portal';
                break;
        }
        document.title = title;

        if (faviconImg) {
            const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
            favicon.type = 'image/x-icon';
            favicon.rel = 'shortcut icon';
            favicon.href = faviconImg;
            document.getElementsByTagName('head')[0].appendChild(favicon);
        }

    }

    // Sets the theme based on the business unit
    _getTheme() {
        if (siteTypeByBusinessUnit(this.context.state.businessUnit) === 'WNI') {
            return WiLineTheme;
        } else if (siteTypeByBusinessUnit(this.context.state.businessUnit) === 'SNI') {
            return StephouseTheme;
        }
        return DefaultTheme;
    }

    render() {
        return (
            <ThemeProvider theme={this._getTheme()}>
                <RefreshScreen>
                    <main>
                        <Switch>
                            <Route exact path={"/"} render={() => (
                                this.state.loggedIn
                                    ? <Redirect to={"/business"}/>
                                    : <Redirect to={"/login"}/>
                            )}/>
                            <Route path={"/login"} component={Login}/>
                            <Route path={"/register"} component={RegisterNewUser}/>
                            <Route path={"/register-user-details"} component={RegisterUserDetails}/>
                            <Route path={"/forgot-password"} component={ForgotPassword}/>
                            <Route path={"/change-password/:token/:email"} component={ChangePassword}/>
                            <Route path={"/user-registration/:token/:email"} component={RegisterUserDetails}/>
                            <Route path={"/help"} component={Help}/>
                            <PrivateRoute adminOnly={false} path={"/business"} component={YourBusinessAccount}/>
                            <PrivateRoute adminOnly={true} path={"/admin"} component={AdminTools}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </main>
                    <GlobalModal/>
                </RefreshScreen>
            </ThemeProvider>
        );
    }
}

App.contextType = AppContext;
export {App};
