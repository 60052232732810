import React, {Component} from 'react';
import {AppContext} from '../../contexts/PortalContext.js';
import SectionTitle from '../SectionTitle.jsx';
import BillingAddress from './BillingAddress.js';
import ServiceAddress from './ServiceAddress.jsx'
import ErrorBoundary from '../ErrorBoundary';
import {enterComponent} from '../animated.js'
// Material UI
import {Grid} from '@material-ui/core';
// SASS Styles
import style from '../styles/AccountSummary.module.scss';
import axios from "axios";
import {catchAxios, handleServerStatus} from "../utils/utils";
import LoaderPage from "../LoaderPage";

/**
 *  This Component renders a section that displays more details about the user's billing account.  It renders a {@link module:BillingAddress BillingAddress} Compone which displays billing contact details, as well as a {@link module:ServiceAddress ServiceAddress} component that displays the addresses serviced for this billing account. Information is queried from the API upon component mounting and stored in state to be passed to children components.
 *  @module AccountDetailsScreen
 *  @see {@link module:BillingAddress BillingAddress}, </br> {@link module:ServiceAddress ServiceAddress}
 */

const AnimateGrid = enterComponent(Grid);

class AccountDetailsScreen extends Component {
    // required to deal with state updates on unmounted components.
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            balance: 0,
            detailsData: {},
            siteContactData: {},
            techContactData: {},
            loading: false,
            accountNo: ' '
        }
    }

    componentDidMount() {
        this._isMounted = true; // Prevents state update on unmounted component
        const context = this.context.state;
        const authToken = sessionStorage.token;
        this.setState({loading: true});

        // Get the customer details from the server, and passes the activeBillingAccount stored in the app context.
        axios({
            method: 'post',
            url: '/profile/details',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: {
                accountNo: this.context.state.activeBillingAccount
            }
        }).then(res => {
            if (this._isMounted && res.data && res.data.status) {
                const data = res.data.data;
                this.setState({
                    ...data,
                    accountNo: data.detailsData.billingaccountno,
                });
                context.dispatch({
                    type: 'SET_CUSTOMER',
                    payload: {
                        detailsData: data.detailsData,
                        techContactData: data.techContactData,
                        siteContactData: data.siteContactData
                    }
                });
                context.dispatch({
                    type: 'SET_ACTIVE_BILLING_ACCOUNT',
                    payload: data.detailsData.billingaccountno
                });

                this.setState({loading: false});
            } else if (res.data && !res.data.status) {
                this.setState({loading: false});
                handleServerStatus(res.data.msg);
            }
        }).catch(err => {
            catchAxios(err);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {detailsData} = this.state;

        return (
            <>
                <LoaderPage/>
                <div>
                    <SectionTitle variant="h1" title={`Account #${this.state.accountNo}`} style={style.welcome}/>
                    <div className={`${style.container} ${style.accDetails}`}>
                        <Grid container style={{padding: '0 10px 80px 10px', maxWidth: '100%'}}>
                            <AnimateGrid item xs={12} lg={12} style={{marginBottom: '24px'}}>
                                <ErrorBoundary>
                                    <BillingAddress
                                        accountName={detailsData.customername}
                                        address={detailsData.address ? detailsData.address : ''}
                                        billingContact={`${detailsData.firstname} ${detailsData.lastname}`}
                                        //email={isAdmin ? detailsData.addressbook_email : detailsData.email}
                                        email={detailsData.addressbook_email}
                                        phone={detailsData.telephone ? detailsData.telephone : ''}
                                        fax={detailsData.fax ? detailsData.fax : ''}
                                        cell={detailsData.cell ? detailsData.cell : ''}
                                        history={this.props.history}
                                        link="billing"
                                        loading={this.state.loading}
                                    />
                                </ErrorBoundary>
                            </AnimateGrid>
                            <AnimateGrid item xs={12} lg={12}>
                                <ErrorBoundary>
                                    <ServiceAddress loading={this.state.loading}/>
                                </ErrorBoundary>
                            </AnimateGrid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

AccountDetailsScreen.contextType = AppContext;
export default AccountDetailsScreen
