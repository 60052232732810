import React, { useContext } from 'react';
import { AppContext } from '../contexts/PortalContext.js';
import style from './styles/Aside.module.scss';

function NavLogo() {
  const context = useContext(AppContext);

  return (
    <div className={style.logo}>
      <img
        src={context.state.contextConfig.logo}
        alt={context.state.contextConfig.logoAlt}
      />
    </div>
  )
}

export default NavLogo;
