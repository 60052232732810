import React from 'react';
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * This Component renders an internal back button. It requires a history prop.
 * @module BackButton
 * @extends Component
 * @param {Object} props - Requires the history object to be passed as "history" prop
 */

function BackButton(props) {
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        aria-label="Back"
        onClick={() => props.history.goBack()}
      >
        <FontAwesomeIcon style={{marginRight: '8px'}} icon={['far', 'arrow-alt-left']}/>
          <span style={{textTransform: 'uppercase'}}>Back</span>
      </Button>
    </div>
  )
}

export default BackButton
