import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ErrorMessage, Form, Field, Formik} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader.jsx';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@material-ui/core';
import Footer from './Footer';
import Header from './Header';
import { FadeIn } from './animated.js'
import TextFieldFormik from './TextFieldFormik'; // Use Formik as a wrapper for material-ui TextField
import axios from 'axios';
import style from './styles/Register.module.scss';
import * as yup from "yup";
import {ReCaptcha} from 'react-recaptcha-v3'
import {verifyCaptchaToken} from "./utils/utils";
import {AppContext} from '../contexts/PortalContext';
import { withTheme } from '@material-ui/core/styles'
import Slide from "@material-ui/core/Slide";

const AnimatedError = FadeIn(ErrorMessage);
const AnimatedDiv = FadeIn('div');

// adds a slide up transition for the dialog box
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class RegisterNewUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEmailVerified: false,  // does email exist in the db
            isVerified: {
                status: false,
                msg: ''
            },
            recaptchaVerified: false,
            isLoading: false,
            dialogOpen: false
        };
    }

    handleSubmit = (values, {props, setSubmitting, setStatus}) => {
        const {email} = values;
        this.setState({isLoading: true});
        // Recaptcha validation return message on error.
        if (!this.state.recaptchaVerified) {
            return this.setState({
                isVerified: {msg: 'Recaptcha thinks you are a bot. Please refresh the page and try again.'},
                isLoading: false
            });
        }

        axios.post('/users/new-user', {email})
            .then(data => {
                data = data.data;
                this.setState({isVerified: {status: data.status, msg: data.msg}, isLoading: false});
                if (data.status) {
                    // return this.props.history.push('/thank-you');
                    this.setState({dialogOpen: !this.state.dialogOpen})
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({isVerified: {status: false, msg: err}, isLoading: false})
            });
    };

    // Get recaptcha user token
    verifyCallback = async (recaptchaToken) => { // Here you will get the final recaptchaToken!!!;
        if (recaptchaToken) {
            if (await verifyCaptchaToken(recaptchaToken) === true) {
                this.setState({
                    recaptchaVerified: true,
                });
            } else {
                this.setState({
                    recaptchaVerified: await verifyCaptchaToken(recaptchaToken),
                    error: 'Error verifying CAPTCHA, please try clearing your cache and reloading the page.'
                });
            }
        }
    };

    handleClose = () => {
        this.setState({dialogOpen: false});
    };

    render() {
        if (this.state.isEmailVerified === true) {
            return <Redirect to={{
                pathname: '/register-user-details',
                state: {email: this.state.email}
            }}/>
        }

        const {handleBlur} = this.props;

        const {
            isVerified,
            recaptchaVerified,
            isLoading
        } = this.state;

        return (
            <AppContext.Consumer>
                {context => {
                    return (

                        <div className={style.container} style={{backgroundColor: this.props.theme.palette.primary.main}}>

                            <Header/>

                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                validationSchema={yup.object().shape({
                                    email: yup.string().email('It should be a valid email address.').required('Your email is required.')
                                })}
                                onSubmit={(values, actions) => {
                                    actions.setStatus(null); // Reset status on every submit
                                    this.handleSubmit(values, actions);
                                }}
                                render={({errors, isSubmitting, values, setStatus, status}) => (
                                    <Form>
                                        <div className={`${style.formContainer} ${style.shadow}`}
                                             style={{overflow: 'hidden', height: '254px'}}>
                                            <h1 className={style.formTitle}>Register New User</h1>
                                            <Field
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                onBlur={handleBlur}
                                                margin="normal"
                                                variant="outlined"
                                                style={{gridColumn: '1/8'}}
                                                placeholder="Email"
                                                type='email'
                                                component={TextFieldFormik}
                                            />
                                            <AnimatedError name="email" component="div" className={style.error}/>
                                            { (isVerified.msg !== '') &&
                                            <AnimatedDiv className={(isVerified.status) ? style.success : style.error}>{isVerified.msg}</AnimatedDiv>
                                            }
                                            { isLoading && <Loader /> }
                                            <Grid container justify="space-between" style={{gridColumn: '1/-1'}}>
                                            <Link to="/" style={{width: '48%'}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{width: '100%'}}
                                                    >
                                                    {<FontAwesomeIcon style={{paddingRight: '4px'}}
                                                                      icon={['fas', 'arrow-alt-circle-left']}/>}
                                                    Back
                                                </Button>
                                            </Link>
                                            <div style={{width: '48%'}}>
                                            {(isLoading || !recaptchaVerified) ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled
                                                    style={{width: '100%'}}
                                                >
                                                    Loading...
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    style={{width: '100%'}}
                                                >
                                                    Submit
                                                </Button>
                                            }
                                          </div>
                                          </Grid>
                                        </div>
                                    </Form>
                                )}
                            />
                            <ReCaptcha
                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                action='login'
                                verifyCallback={this.verifyCallback}
                            />
                            <Footer/>
                            <Dialog
                                open={this.state.dialogOpen || false}
                                onClose={this.handleClose}
                                maxWidth="md"
                                aria-labelledby="dialog-title"
                                TransitionComponent={Transition}
                                keepMounted
                            >
                                <DialogTitle id="dialog-title" onClose={this.handleClose}>
                                    {`Thank You`}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography gutterBottom variant="h5">
                                        We are processing your request.<br />
                                        Please wait a few minutes and check your email account.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary" variant="contained">
                                        Dismiss
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )
                }}
            </AppContext.Consumer>
        )
    }
}

export default withTheme(RegisterNewUser)
