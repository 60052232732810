import React, {useContext} from 'react';
import {AppContext} from '../contexts/PortalContext.js';
import LoadingOverlay from 'react-loading-overlay';

// Loading colors
const loadingColors = {
    background: '#E9F1F8',
    text: '#474747'
}

/**
 * Checks if the refresh token is currently loading.
 * Displays loading screen
 * @param {Object} props Child components
 */
function RefreshScreen(props) {
    const context = useContext(AppContext);
    const globalLoad = context.state.globalLoad; // Check if refresh token is loading
    const loadingText = (props.loadingText) ? props.loadingText : 'Loading content...';

    return (
        <LoadingOverlay
            active={globalLoad}
            spinner
            fadeSpeed={globalLoad ? 0 : 200} // No initial fade, only exit fade
            styles={{
                wrapper: (base) => ({
                    ...base,
                    height: '100vh',
                    width: '100vw',
                    position: 'absolute'
                }),
                overlay: (base) => ({
                    ...base,
                    background: loadingColors.background,
                    'z-index': '1800'// Needs to be set so loading screen is above material ui 
                }),
                content: (base) => ({
                    ...base,
                    'color': loadingColors.text
                }),
                spinner: (base) => ({
                    ...base,
                    '& svg circle': {
                        stroke: loadingColors.text,
                    }
                })
            }}
            text={loadingText}
        >
            <>
                {props.children}
            </>
        </LoadingOverlay>
    )
}

export default RefreshScreen;