import React, {Component} from 'react';
import Footer from './Footer';
import Header from './Header';
import {verifyCaptchaToken} from "./utils/utils";
import {ErrorMessage, Form, Formik} from 'formik';
import Loader from './Loader.jsx';
import * as yup from 'yup';
import {Link} from 'react-router-dom';
import Loading from './Loading.jsx';
import {
    TextField,
    Button,
    withTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
} from "@material-ui/core";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ReCaptcha} from "react-recaptcha-v3";
import axios from 'axios';
import style from './styles/Register.module.scss';
import Slide from "@material-ui/core/Slide";

// adds a slide up transition for the dialog box
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEmailVerified: false,
            recaptchaVerified: false,
            submitStatus: false,
            submitMsg: '',
            isLoading: false,
            dialogOpen: false
        };
    }

    handleSubmit = (values) => {
        this.setState({isLoading: true});
        // verify recaptcha
        if (!this.state.recaptchaVerified) {
            this.setState({
                submitMsg: 'There is a problem with ReCaptcha, please refresh the page.',
                submitStatus: false
            });
        } else if (values.email) {
            this.setState({isLoading: true});
            // sends the password reset request to the server
            axios.post('/password-reset/request', values)
                .then(res => {
                    this.setState({submitMsg: res.data.msg, submitStatus: res.data.status});
                    this.setState({isLoading: false});
                    if (res.data.status) {
                        //return this.props.history.push('/thank-you');
                        this.setState({dialogOpen: !this.state.dialogOpen})
                    }
                })
                .catch(err => {
                    this.setState({isLoading: false});
                });
        }
        setTimeout( () => {
            this.setState({submitStatus: true})
        }, 2000)
    };

    // Get recaptcha user token
    verifyCallback = async (recaptchaToken) => { // Here you will get the final recaptchaToken!!!;
        if (recaptchaToken) {
            if (await verifyCaptchaToken(recaptchaToken) === true) {
                this.setState({
                    recaptchaVerified: true,
                });
            } else {
                this.setState({
                    recaptchaVerified: await verifyCaptchaToken(recaptchaToken),
                    error: 'Error verifying CAPTCHA, please try clearing your cache and reloading the page.'
                });
            }
        }
    };

    handleClose = () => {
        this.setState({dialogOpen: false});
    };

    render() {
        // Email Validation Schema
        const validationSchema = yup.object().shape({
            email: yup.string()
                .email('Please enter a valid email')
                .required('Please enter an email')
        });

        return (
            <div className={style.container} style={{backgroundColor: this.props.theme.palette.primary.main}}>
                <Header/>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={(values, actions) => {
                        this.handleSubmit(values, actions);
                    }}
                    render={props => (
                        <Form style={{height: '256px'}}>
                            <div className={`${style.formContainer} ${style.shadow}`} style={{overflow: 'hidden'}}>
                                <h1 className={style.formTitle}>Forgot Password?</h1>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    onChange={(values) => {
                                        this.setState({submitMsg: ''});
                                        props.handleChange(values);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    margin="normal"
                                    variant="outlined"
                                    style={{gridColumn: '1/8'}}
                                />
                                <ErrorMessage name="email" component="div" className={style.error}/>
                                {(this.state.submitMsg !== '') &&
                                <div
                                    className={(this.state.submitStatus) ? style.success : style.error}>{this.state.submitMsg}</div>
                                }
                                {this.state.isLoading && <Loader/>}
                                <div className={style.buttonContainer}>
                                    <Link to="/" style={{width: '47%'}}>
                                        <Button variant="contained" color="primary"
                                                style={{maxHeight: '36px', width: '100%'}}>
                                            {<FontAwesomeIcon style={{paddingRight: '4px'}}
                                                              icon={['fas', 'arrow-alt-circle-left']}/>}
                                            BACK
                                        </Button>
                                    </Link>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        style={{maxHeight: '36px', width: '47%'}}
                                        disabled={this.state.isLoading || !this.state.recaptchaVerified}
                                    >
                                        <Loading loading={this.state.isLoading || !this.state.recaptchaVerified}
                                                 submitText="Submit" loadingText="loading"/>
                                    </Button>
                                </div>
                            </div>
                            <ReCaptcha
                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                action='login'
                                verifyCallback={this.verifyCallback}
                            />
                        </Form>
                    )}
                />
                <Footer/>
                <Dialog
                    open={this.state.dialogOpen || false}
                    onClose={this.handleClose}
                    maxWidth="md"
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    keepMounted
                >
                    <DialogTitle id="dialog-title" onClose={this.handleClose}>
                        {`Thank You`}
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom variant="h5">
                            We are processing your request.<br />
                            Please wait a few minutes and check your email account.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="contained">
                            Dismiss
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withTheme(ForgotPassword);
