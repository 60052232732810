import React, {Component} from 'react';
import axios from 'axios';
import NoScrollTable from '../NoScrollTable';
import {formatDate, convertCurrency} from '../utils/utils.js'
import {catchAxios} from "../utils/utils";

/**
 * Data container for recent customer payment information. Gets customer payment information and then renders a non-scrolling table.
 * @module PaymentDataContainer
 */
class PaymentDataContainer extends Component {
    _isMounted = false;
    state = {
        loading: false,
        error: '',
        headers: ['POSTING DATE', 'METHOD', 'AMOUNT', 'INVOICE DATE'],
        success: '',
        data: [],
        offset: 0,
        limit: 6
    };

    /**
     * Formats payment data for output
     * @function buildData
     */
    buildData = (data) => {

        if (!data || data.length === 0) {
            return data;
        }

        return data.map(e => {
            return [
                formatDate(e.postingdate),
                e.paymentmethod,
                convertCurrency(e.amount),
                e.invoicedate,
            ]
        });
    };

    /**
     * Gets payment data from server using offset and limit
     * @function getData
     */
    getData = () => {
        // Update loading and offset
        this.setState({
            loading: true,
            offset: this.state.offset + this.state.limit
        });

        const authToken = sessionStorage.getItem('token');

        axios({
            method: 'post',
            url: '/invoices/pay-history',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: {
                offset: this.state.offset,
                limit: this.state.limit
            }
        }).then((response) => {
            if (this._isMounted) {
                this.setState({loading: false});

                // Check for correct response format
                if (response.data && response.data.data) {
                    if (response.data.status === true) {
                        this.setState({
                            success: response.data.msg,
                            data: response.data.data
                        });
                    } else if (response.data.status === false) {
                        this.setState({error: response.data.msg});
                    }
                } else {
                    this.setState({error: 'There was a problem retrieving your call data.'});
                }
            }
        }).catch((err) => {
            this.setState({loading: false});
            this.setState({error: 'There was a problem retrieving your call data.'});
            catchAxios(err);
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getData();
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    render() {
        const {data} = this.state;
        const rows = this.buildData(data);

        return (
            <NoScrollTable
                headers={this.state.headers}
                loading={this.state.loading}
                rows={rows}
                minCellWidth={140}
                tableHeight={140}
                limit={6}
                link="documents/payment-history"
                style={{marginTop: 0}}
                dataName="payments"
            />
        )
    }
}

export default PaymentDataContainer;
