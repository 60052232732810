import React,  {useContext} from 'react';
import {AppContext} from '../contexts/PortalContext';
import {makeStyles, Typography} from "@material-ui/core";
import style from "./styles/HelpSection.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generateKey} from "./utils/utils";

const useStyles = makeStyles( theme => ({
    icon: {
        color: theme.palette.primary.main,
    },
    title: {
        gridColumn: '1/-1',
        paddingLeft: '2vw',
        fontSize: '2em',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 300
    },
    guideContentTitle: {
        fontSize: '1.4rem',
        textTransform: 'uppercase',
        fontWeight: 400,
    },
    guideContent: {

    },
}));

export default function QuickGuideContent()  {
    const context = useContext(AppContext);
    const classes = useStyles();

    const guideContent = [
        {
            id: 0,
            title: '',
            icon: [],
            content: <span style={{fontSize: '1.3em', color: context.state.contextConfig.color}}>Please note that customer email addresses are associated with our billing system. Therefore, before you
                start the login process, contact the WiLine customer service team to make sure we have the correct email address in our system.</span>
        },
        {
            id: 1,
            title: 'Create Account',
            icon: ['fal', 'user-circle'],
            content: [
                [`Go to `, <strong key={generateKey('st1')}>{context.state.contextConfig.portalUrl}</strong>,'.'],
                ['Click on ',  <strong key={generateKey('st2')}>REGISTER</strong>, '.'],
                ['Enter your email address.'],
                [`Verify the new account by going to your inbox and opening the email from `, <strong key={generateKey('st2')}>{context.state.contextConfig.portalUrl}</strong>, ` then click the link provided.`],
                ['Create password.']
            ]
        },
        {
            id: 2,
            title: 'Payment Method Setup',
            icon: ['fal', 'badge-dollar'],
            content: [
                ['Click the ', <strong key={generateKey('st4')}>PAYMENTS</strong>, ' tab and then click Add Payment method. Enter credit card or' +
                ' bank account details.'],
                ['After payment details have been added, navigate to Payment Methods. Click ', <strong key={generateKey('st5')}>SET AS AUTOPAY</strong>, ' to choose a' +
                ' default payment option.']
            ]
        },
        {
            id: 3,
            title: 'Pay Invoice',
            icon: ['fal', 'file-invoice'],
            content: [
                ['In the ', <strong key={generateKey('st6')}>CURRENT BALANCE</strong>, ' section, click ', <strong key={generateKey('st7')}>MAKE A PAYMENT</strong>,'.'],
                ['Review your payment details and click ', <strong key={generateKey('st8')}>SUBMIT</strong>,'.']
            ]
        },
        {
            id: 4,
            title: 'Payment Confirmation',
            icon: ['fal', 'file-check'],
            content: [
                'A Payment Confirmation page will appear once your payment is accepted and an email will be sent to your registered email address.',
                'If the payment is not accepted, an error message will appear and an email will be sent with further instructions.'
            ]
        },
        {
            id: 5,
            title: 'Documents Access & Payment History',
            icon: ['fal', 'file-chart-line'],
            content: [
                ['Go to ', <strong key={generateKey('st7')}>DOCUMENTS</strong>, ' to view your invoices and payment history.']
            ]
        }
        // {
        //     id: 6,
        //     title: 'Payment Confirmation',
        //     icon: ['fal', 'check-circle'],
        //     content: [
        //         'If the payment is successful, a the Payment Confirmation page opens and an email is sent to your registered email account.',
        //         'If your payment has not been accepted, a warning message appears and an email is sent to your registered email account.'
        //     ]
        // }
    ];

    return (
        <div>
            {guideContent.map(content => {
                return <div key={generateKey(content.id)} className={style.sectionContent}>
                    <div className={classes.title}>
                        <Typography component="h2" color="primary" className={classes.guideContentTitle}>{content.title}</Typography>
                    </div>
                    <div className={`${style.guideContent}`}>
                        <div className={style.guideIcon}>
                            {content.icon && <FontAwesomeIcon icon={content.icon} size="6x" className={classes.icon} />}
                        </div>
                        {
                            (Array.isArray(content.content)) ?
                                <div className={style.guideList}>
                                    <ul>
                                        {
                                            content.content.map( (item, index) => {
                                                return <li key={generateKey(item[0]+index)}><FontAwesomeIcon icon={['fal', 'arrow-right']} className={classes.icon} /> {item}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                                :
                                <div className="guide-list" style={{gridColumn: 'span 4'}}>
                                    <p style={{gridColumn: 'span 4'}}>{content.content}</p>
                                </div>
                        }
                    </div>
                </div>;
            })
        }
        </div>
    );
};

