import React from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import '../styles.css';

/* Component Views */
import MyAccount from './myAccount/MyAccount.jsx';
import Documents from './documents/Documents'
import Payments from './payments/Payments'
import Help from './help/Help'
import style from './styles/MainLayout.module.scss'
import NestedDrawer from './NestedDrawer.js'
import SimpleNav from './SimpleNav.js';
import MultipleAccounts from './myAccount/MultipleAccounts';
import NotFound from './NotFound.jsx';

// views
import {AppContext} from '../contexts/PortalContext'

/**
 * Handles main routing for the nested drawer (aside navigation). The aside is not visible if user is at /business.
 * @module BusinessAccount
 */
const BusinessAccount = (props) => {
    const routerLocation = props.history.location.pathname;

    if (routerLocation === '/business/') {
        return (<Redirect to="/business"/>)
    } else {
        return (
            <AppContext.Consumer>
                {
                    context => {
                        return (
                            <div className={style.layout}>
                                {routerLocation !== '/business' ? <NestedDrawer/> : <SimpleNav/>}
                                <div className={style.gridFix}>
                                    <div>
                                        <Switch>
                                            <Route exact path="/business" component={MultipleAccounts}/>
                                            <Route path="/business/my-account" component={MyAccount}/>
                                            <Route path="/business/documents" component={Documents}/>
                                            <Route path="/business/payments" component={Payments}/>
                                            <Route path="/business/help" component={Help}/>
                                            <Route component={NotFound}/>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            </AppContext.Consumer>
        )
    }
};

// Let drawer access router location
const BusinessAccountWithRouter = withRouter(BusinessAccount);
export default BusinessAccountWithRouter;