import React, {Component} from 'react';
import axios from 'axios';
import {AppContext} from '../../contexts/PortalContext.js';
import {getPayMethods} from '../utils/paymentUtils.js';
import delayUnmounting from '../DelayUnmounting.js';
import {catchAxios} from '../utils/utils.js';
import {withRouter} from 'react-router-dom';
// Form stages components
import PaymentDetails from './PaymentDetails';
import PaymentReview from './PaymentReview';
import PaymentSuccess from './PaymentSuccess';
import AccountSummary from "../myAccount/AccountSummary";
import LoaderPage from "../LoaderPage";

/**
 * This is the parent component that for {@link module:PaymentDetails PaymentDetails}, {@link module:PaymentReview PaymentReview} and {@link module:PaymentSuccess PaymentSuccess}.  It handles all of the logic provided by the forms contained within PaymentDetails and PaymentReview. </br>
 * After a payment is completed, it will redirect the user to the {@link module:AccountSummary AccountSummary} page.
 * @module MakeAPayment
 *
 * @param {function} nextStep - Takes a string as an argument.  If the the string is 'confirm', it increments this.state.step; otherwise it calls prevStep()
 * @param {function} prevStep - Decrements the this.state.step
 * @param {function} paymentValues - Takes an object (values) as an argument and supplies their values to state.
 * @param {function} confirmPayment - Calls the server make a payment using values stored in state.
 * @see {@link module:PaymentDetails PaymentDetails}, </br> {@link module:PaymentReview PaymentReview}, </br> {@link module:PaymentSuccess PaymentSuccess}, </br>{@link module:AccountSummary AccountSummary}
 */


const DelayedDetails = delayUnmounting(PaymentDetails);
const DelayedReview = delayUnmounting(PaymentReview);

class MakeAPayment extends Component {
    state = {
        step: 1,
        amountDue: '',
        amountPayable: '',
        paymentMethods: [],
        selectedPaymentMethod: '',
        payment: {},
        loading: true,
        successMessage: '',
        errorMessage: '',
        detailsMounted: false,
        reviewMounted: false,
        addPaymentStatus: '', // Status, only if a user was redirected from adding a payment method
        loaderEmail: false
    };

    componentDidMount() {
        // Sets redirect props in state if they exist
        // Ony if user has been redirected from adding a payment method
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.status
        ) {
            this.setState({
                addPaymentStatus: this.props.location.state.status
            })
        }

        this.setState({detailsMounted: !this.state.detailsMounted}); // necessary for initial transition animations
        const context = this.context.state;
        const token = sessionStorage.token;

        if (token) {
            // get payment methods
            this.setState({loading: true});
            getPayMethods(this.context.state.activeBillingAccount).then(res => {
                this.setState({paymentMethods: res.data.data || []})
            }).catch(err => {
                catchAxios(err)
            });

            // get current balance
            axios({
                method: 'post',
                url: '/profile/current-balance',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                const data = res.data.data;
                context.dispatch({
                    type: 'SET_BALANCE',
                    payload: data
                });
                this.setState({
                    amountDue: data,
                    loading: false
                })
            }).catch(err => {
                this.setState({loading: false});
                catchAxios(err);
            });
        }
    }

    // Resets and reloads form after unsuccessful payment attempt
    reset = () => {
        // Reset state
        this.setState({
            step: 1,
            amountDue: '',
            amountPayable: '',
            paymentMethods: [],
            selectedPaymentMethod: '',
            payment: {},
            loading: true,
            successMessage: '',
            errorMessage: '',
            detailsMounted: false,
            reviewMounted: false,
            addPaymentStatus: ''
        });

        // Refresh page
        this.props.history.push('/business/payments')
    };

    reportErrorByEmail = () => {
        this.setState({loaderEmail: true});
        const authToken = sessionStorage.token;
        if (authToken) {
            axios({
                method: 'post',
                url: '/payments/report-payment-error',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            }).then(data => {
                if (data.data.msg) {
                    this.context.state.dispatch({
                        type: 'SET_GLOBAL_MODAL',
                        payload: {open: true, title: data.data.msg}
                    });
                }
                this.setState({loaderEmail: false});
            }).catch(err => {
                this.setState({loaderEmail: false});
                catchAxios(err);
            });
        }
    };

    // Next form screen
    nextStep = (type) => {
        this.setState({detailsMounted: false});

        setTimeout(() => {
            if (type === 'confirm') {
                // Clear previous messages
                this.setState({
                    successMessage: '',
                    errorMessage: ''
                });
                this.makePayment().then(res => {
                    if (res.data.status) {
                        this.setState({
                            loading: false,
                            step: this.state.step + 1,
                            payment: res.data.payment,
                            successMessage: res.data.msg
                        });
                    } else {
                        this.setState({
                            loading: false,
                            step: this.state.step + 1,
                            payment: res.data.payment,
                            errorMessage: res.data.msg
                        });
                    }
                }).catch(err => {
                    this.setState({loading: false});
                    catchAxios(err);
                });
            } else {
                this.setState({
                    step: this.state.step + 1,
                    reviewMounted: true,
                });
            }
        }, 399)  // set timer on function to same duration as transition animation - 1. This prevents trying to perform a state update on an unmounted component
    };

    //Previous form Screen
    prevStep = () => {
        this.setState({reviewMounted: false});
        setTimeout(() => {
            const {step} = this.state;
            this.setState({
                step: step - 1,
                detailsMounted: true,
            });
        }, 399) // set timer on function to same duration as transition animation - 1.  This prevents trying to perform a state update on an unmounted component
    };

    // Get values from form on submit
    paymentValues = (values) => {
        this.setState({
            amountPayable: values.amountPayable,
            selectedPaymentMethod: values.selectedPaymentMethod
        });
        this.nextStep();
    };

    // Confirm Payment Request
    makePayment = async () => {
        this.setState({loading: true});
        const token = sessionStorage.token;

        return axios({
            method: 'post',
            url: '/payments/make-payment',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                // There is no need to pass a billingAccountNo here as it can be picked from the server session.
                payId: this.state.selectedPaymentMethod.pay_account_id,
                amount: this.state.amountPayable
            }
        })
    };

    render() {
        const context = this.context.state;
        const {step} = this.state;
        const {amountDue, amountPayable, paymentMethods, selectedPaymentMethod, selectedPayType} = this.state;
        const data = {amountDue, amountPayable, paymentMethods, selectedPaymentMethod, selectedPayType};

        switch (step) {
            case 1:
                return (
                    <>
                        <LoaderPage/>
                        <DelayedDetails
                            delayTime={401}
                            ismounted={this.state.detailsMounted}
                            nextStep={this.nextStep}
                            submitPayment={this.paymentValues}
                            data={data}
                            loading={this.state.loading}
                            addPaymentStatus={this.state.addPaymentStatus} // From router state, if customer added payment method
                        />
                    </>
                );
            case 2:
                return (
                    <DelayedReview
                        delayTime={401}
                        ismounted={this.state.reviewMounted}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        confirmPayment={this.makePayment}
                        values={data}
                        error={this.state.errorMessage}
                        loading={this.state.loading}
                        addPaymentStatus={this.state.addPaymentStatus} // From router state, if customer added payment method
                    />
                );
            case 3:
                return <PaymentSuccess
                    loaderEmail={this.state.loaderEmail}
                    errorMessage={this.state.errorMessage}
                    successMessage={this.state.successMessage}
                    payment={this.state.payment}
                    reset={this.reset}
                    reportErrorByEmail={this.reportErrorByEmail}
                    billingEmail={context.contextConfig.billingEmail}
                />;
            default:
                return <AccountSummary/>
        }
    }
}

MakeAPayment.contextType = AppContext;
export default withRouter(MakeAPayment);
