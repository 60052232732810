import React, {Component} from 'react';
import {select} from 'd3-selection';
import {arc} from 'd3-shape';
import {color} from 'd3-color';
import {interpolate} from 'd3-interpolate';

class Slice extends Component {
    _isMounted = false;

    arc = arc()
        .innerRadius(60)
        .outerRadius(100)
        .padAngle(.02)
        .padRadius(100)
        .cornerRadius(4);

    state = {
        d: this.props.d,
        index: this.props.index,
        color: this.props.color,
        currentColor: this.props.color,
        dPath: this.arc(this.props.d),
        animating: false
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            color: this.props.color,
            currentColor: this.props.color,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this._isMounted && prevProps.d !== this.props.d) {
            this.setState({
                color: this.props.color,
                currentColor: this.props.color,
            });

            select(this.refs.elem)
                .transition()
                .duration(800)
                .attrTween("d", this.arcTween(this.state.d, this.props.d, this.arc))
                .on("end", () =>
                    this.setState({
                        d: this.props.d,
                        pathD: this.arc(this.props.d),
                        animating: false
                    })
                );
        }
    }

    mouseOver() {
        if (!this.state.color) {return} // Only change color if a color exists

        let newColor = color(this.state.color);
        newColor.opacity = 0.8;

        if (this._isMounted && this.state.animating === false) {
            select(this.refs.elem)
                .transition()
                .duration(200)
                .attr("fill", newColor)
                .on("end", () =>
                    this.setState({
                        currentColor: newColor,
                        animating: false
                    })
                );
        }
    }

    mouseOut() {
        if (!this.state.color) {return} // Only change color if a color exists

        if (this._isMounted && this.state.animating === false) {
            select(this.refs.elem)
                .transition()
                .duration(200)
                .attr("fill", this.state.color)
                .on("end", () =>
                    this.setState({
                        currentColor: this.state.color,
                    })
                );
        }
    }

    arcTween = (oldData, newData, arc) => {
        const copy = { ...oldData };
        return function() {
            const interpolateStartAngle = interpolate(
                    oldData.startAngle,
                    newData.startAngle
                ),
                interpolateEndAngle = interpolate(
                    oldData.endAngle,
                    newData.endAngle
                );

            return function(t) {
                copy.startAngle = interpolateStartAngle(t);
                copy.endAngle = interpolateEndAngle(t);
                return arc(copy);
            };
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <g onClick={() => {
                    if (this.props.getDetails !== null) this.props.getDetails(this.state.d.data.label)
                }}
            >
                <path
                  d={this.state.dPath}
                  fill={this.state.currentColor}
                  ref='elem'
                  onMouseEnter={this.mouseOver.bind(this)}
                  onMouseLeave={this.mouseOut.bind(this)}
                />
            </g>
        )
    }
};

export default Slice;
