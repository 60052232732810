import React from 'react';
import {Link} from 'react-router-dom';
import {AppContext} from '../../contexts/PortalContext.js';
import SectionTitle from "../SectionTitle.jsx";
import {Grid, Typography} from "@material-ui/core";
import SelectPropertyOrCard from '../SelectPropertyOrCard';
import {getPayMethods, isExpired} from '../utils/paymentUtils.js';
import {FadeIn} from '../animated.js';
// SASS Styles
import style from "../styles/Payments.module.scss";
import styled from 'styled-components';
import ErrorBoundary from "../ErrorBoundary";
import Loader from '../Loader.jsx';
import axios from 'axios';
import CardModal from './CardModal';
import {catchAxios, getSessionStorage} from "../utils/utils";
import LoaderPage from "../LoaderPage";

/**
 *  This Component renders the Payment methods available to the users account.
 *  @module PaymentMethods
 *  @param {function} handleRemoveCard - Takes single argument: string.  The string should be the unique identifier associated with the card to be removed.  Returns a new array of payment methods excluding the removed card.
 *  @param {function} toggleModal - Takes no arguments. Toggles state.open.
 *  @param {function} setActiveAccountId - Takes 3 arguments: payId, index, type. Sets these values to state.
 *  @param {function} removeCard - Takes no arguments.  Calls API to remove a payment option.
 *  @param {function} setAutoPay - Takes no argument.  Calls API to set a payment option to auto-pay.
 *  @see {@link module:CardModal CardModal}, </br> {@link module:ErrorBoundary ErrorBoundary}, </br> {@link module:SectionTitle SectionTitle}
 */

const WrappedSelectCard = FadeIn(SelectPropertyOrCard);

const Hidden = styled(SelectPropertyOrCard)`
  visibility: hidden;
`;

class PaymentMethods extends React.Component {

    state = {
        loading: true,
        modalLoading: false,
        cards: [],
        open: false,
        confirmationMsg: '',
        err: '',
        type: '',
        payId: null, // payId to remove
        index: null  // payment index to remove
    };

    handleRemoveCard(id) {
        let newCards = this.state.cards.filter(card => card.pay_account_id !== id);
        this.setState({cards: newCards});
    }

    async componentDidMount() {
        this.setState({loading: true});
        const payMethods = await getPayMethods();
        if (payMethods.data.status) {
            this.setState({cards: payMethods.data.data});
        }
        this.setState({loading: false});
    }

    toggleModal = () => {
        // Clear status messages if modal is open
        if (this.state.open) {
            this.setState({open: false});

            // Quick fix to prevent state change bug
            setTimeout(() => {
                this.setState({err: '', confirmationMsg: ''});
            }, 500);
        } else {
            this.setState({open: true});
        }
    };

    // Sets the payment information to be removed
    setActiveAccountID = (payId, index, type) => {
        this.setState({
            payId,
            index,
            type,
            open: true
        })
    };

    removeCard = () => {
        const payId = this.state.payId; // payId to remove
        const index = this.state.index; // Index to remove

        this.setState({modalLoading: true, err: ''});
        const authToken = sessionStorage.token;
        if (authToken) {
            axios({
                method: 'post',
                url: '/payments/remove-pay-method',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                data: {
                    payId
                }
            }).then(res => {
                if (res.data.status === true) {
                    this.handleRemoveCard(payId);

                    this.context.state.dispatch({
                        type: "REMOVE_PAYMENT_OPTION",
                        payload: index
                    });

                    this.setState({
                        modalLoading: false,
                        confirmationMsg: res.data.msg,
                    })
                } else {
                    this.setState({
                        modalLoading: false,
                        err: res.data.msg
                    })
                }
            }).catch(err => {
                this.setState({
                    modalLoading: false,
                    err: 'There was a problem processing your request.'
                });
                catchAxios(err);
            });
        } else {
            this.setState({modalLoading: false, err: 'There was a problem with parameters'});
        }
    };

    removeAutoPay = () => {
        this.setState({modalLoading: true, err: ''});
        const authToken = getSessionStorage('token');
        const payId = this.state.payId;
        if (authToken && payId) {
            axios({
                method: 'post',
                url: 'payments/disallow',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                data: {
                    payId
                }
            }).then(res => {
                if (res.data.status) {
                    this.setState({modalLoading: false, confirmationMsg: res.data.msg, cards: res.data.cards});
                } else {
                    this.setState({modalLoading: false, err: 'There was a problem processing your request.'});
                }
            }).catch(err => {
                catchAxios(err);
            })
        } else {
            this.setState({modalLoading: false, err: 'There was a problem with parameters'});
        }
    };

    // Sets a payment method as default auto-pay option
    setAutoPay = () => {
        this.setState({modalLoading: true, err: ''});
        const authToken = getSessionStorage('token');
        const payId = this.state.payId;
        if (authToken && payId) {
            axios({
                method: 'post',
                url: 'payments/authorize',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                data: {
                    payId
                }
            }).then(res => {
                if (res.data.status) {
                    this.setState({modalLoading: false, confirmationMsg: res.data.msg, cards: res.data.cards});
                } else {
                    this.setState({modalLoading: false, err: 'There was a problem processing your request.'});
                }
            }).catch(err => {
                catchAxios(err);
            })
        } else {
            this.setState({modalLoading: false, err: 'There was a problem with parameters'});
        }
    };

    render() {
        return (
            <>
                <LoaderPage/>
                <ErrorBoundary>
                    <SectionTitle
                        variant="h1"
                        style={style.welcome}
                        title="Your Payment Methods"
                    />
                </ErrorBoundary>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <div className={style.yourCards}>
                            {this.state.loading ?
                                <ErrorBoundary>
                                    <Loader bg="#E9F1F8"/>
                                </ErrorBoundary>
                                :
                                <Grid container spacing={2} justify="flex-start">
                                    {this.state.cards.map((item, index, array) => {
                                        if (item.payType === 'MasterCard' || item.payType === 'Visa' || item.payType === 'Discover' || item.payType === 'AmericanExpress') {
                                            return (
                                                <ErrorBoundary key={item.pay_account_id}>
                                                    <WrappedSelectCard
                                                        delay={'0s'}
                                                        icon={['fab', `cc-${item.payType === 'AmericanExpress' ? 'amex' : item.payType.toLowerCase()}`]}
                                                        iconSize="3x"
                                                        heading={`${item.prefixMask} ${item.lastDigitsCC}`}
                                                        description={item.nameOnAccount}
                                                        setActiveAccount={(type) => this.setActiveAccountID(item.pay_account_id, index, type)}
                                                        payId={item.pay_account_id}
                                                        expired={isExpired(item.expireMonth, item.expireYear)}
                                                        autoPay={item.status === "ACTIVE"}
                                                    />
                                                </ErrorBoundary>
                                            )
                                        } else {  //methodType === ACH
                                            return (
                                                <ErrorBoundary key={item.pay_account_id}>
                                                    <WrappedSelectCard
                                                        delay={'0s'}
                                                        icon={['fas', 'money-check-edit-alt']}
                                                        iconSize="3x"
                                                        heading={`${item.prefixMask} ${item.lastDigitsACH}`}
                                                        description={item.nameOnAccount}
                                                        setActiveAccount={(type) => this.setActiveAccountID(item.pay_account_id, index, type)}
                                                        payId={item.pay_account_id}
                                                        expired={isExpired(item.expireMonth, item.expireYear)}
                                                        autoPay={item.status === "ACTIVE"}
                                                    />
                                                </ErrorBoundary>
                                            )
                                        }
                                    })}
                                    {/* adds invisible SelectPropertyOrCard components to fix grid spacing */}
                                    {/* TODO: Add a proper grid layout that doesn't need hidden dom elements. */}
                                    {this.state.cards.length >= 4 &&
                                    <>
                                        <Hidden icon={['fas', 'money-check-edit-alt']} iconSize="3x" heading="hidden"
                                                description="hidden"/>
                                        <Hidden icon={['fas', 'money-check-edit-alt']} iconSize="3x" heading="hidden"
                                                description="hidden"/>
                                    </>
                                    }
                                </Grid>
                            }
                            {/* display a message and a link if there are no methods on file */}
                            {this.state.cards && this.state.cards.length < 1 && !this.state.loading &&
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Typography variant="h3" style={{lineHeight: '3em'}}>
                                        No payment methods on file.
                                    </Typography>
                                    <Typography variant="h4">Click &nbsp;
                                        <Link to="/business/payments/add-new-card"
                                              style={{fontWeight: 'bold'}}>here</Link>&nbsp;
                                        to add a payment method.
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                        </div>
                    </Grid>
                    <CardModal
                        open={this.state.open}
                        closeModal={this.toggleModal}
                        loading={this.state.modalLoading}
                        type={this.state.type}
                        removeCard={this.removeCard}
                        setAutoPay={this.setAutoPay}
                        removeAutoPay={this.removeAutoPay}
                        confirmationMsg={this.state.confirmationMsg}
                        err={this.state.err}
                    />
                </Grid>
            </>
        )
    }
}

PaymentMethods.contextType = AppContext;
export default PaymentMethods