import Payment from 'payment';
import axios from 'axios';
import SocketIO from "../SocketIO";

const jwtDecode = require('jwt-decode');

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

/**
 * Does not [validate]{@link https://github.com/auth0/jwt-decode#readme} the token.
 * Does not have access to a secret key, so the function simply decodes the token.
 * @function decodeToken
 * @returns {Object} Object of values stored on the token.
 */
export function decodeToken(token) {
    try {
        if (!token) {
            return null
        }
        return jwtDecode(token);
    } catch (err) {
        console.log(err.message);
        return null;
    }
}

/**
 * Formats a credit card number according to the cardtype (e.g. American Express, Visa, etc.)
 * @param {int} value - A credit card number
 * @see {@link https://www.npmjs.com/package/payment payment.js}
 *
 */
export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10,
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10,
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8,
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

/**
 * Correctly formats the CVC of a credit card.
 * @param {int} value - Value supplied by the user
 * @param {} prevValue -
 * @param {Object} allValues - initialized as an empty object in function call.
 *
 */

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

/**
 * Formats the expiration date on a credit card
 * @param {string} value - expiration date
 */

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

/**
 * formats an array into an object
 * @param {array} data - an array
 * @return {Object}
 */

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

/**
 * Calls a google api to verify the recaptcha3 token
 * @param {string} token - a unique token used for verifying
 */
export async function verifyCaptchaToken(token) {
    if (token) {
        try {
            const verify = await axios.post('/auth/recaptcha-verify', {tokenRecaptcha: token});
            return true;//verify.data.status;
        } catch (err) {
            console.error(err);
        }
    }
    return false;
}

/**
 *  Gets an item from session storage
 *  @param {string} params - the param you want returned from session storage
 */
export function getSessionStorage(params) {
    try {
        params = params.split('.');
        if (params.length > 1) {
            let sessionItemData;
            params.map((param) => {
                if (!sessionItemData) {
                    sessionItemData = sessionStorage.getItem(param);
                    if (sessionItemData) {
                        return sessionItemData = JSON.parse(sessionStorage.getItem(param));
                    } else {
                        throw new Error('Fail data');
                    }
                } else {
                    return sessionItemData = sessionItemData[param];
                }
            });
            return sessionItemData;
        } else {
            return sessionStorage.getItem(params[0]);
        }
    } catch (err) {
        return null;
    }
}

/**
 *  Calls the API to clear the server session
 *  @return {boolean}
 *
 */
// Use this to clear the session server
export async function clearServerSession() {
    const authToken = sessionStorage.token;

    try {
        if (authToken) {
            const verify = await axios({
                method: 'post',
                url: '/auth/clear-session',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then(SocketIO('disconnect_socket'));
            sessionStorage.clear();
            return verify.data.status;
        }
    } catch (err) {
        sessionStorage.clear();
    }
    return false;
}

/**
 *  Used to handle the case when a server error occurs and a catch block is triggered.
 *  @param {Object} err - The error object returned from the server
 */
// If error on axios request treatment error
export function catchAxios(err) {
    switch (err.status) {
        case 401:
        default:
            //console.log(err.message);
            sessionStorage.clear();
            window.location.hash = '/login';
            break;
    }
}

/**
 *  Handles a server response where the status is false
 *  @param {string} msg The error msg from the server.
 */
export function handleServerStatus(msg) {
    switch (msg) {
        case 'Error: Invalid Account Number.':
            sessionStorage.clear();
            window.location.hash = '/login';
            break;
        default:
            console.log('Unhandled server status response');
    }
}

/**
 * Formats currency string
 * @param  {string} value  String to format
 * @return {string}        Appropriately formated string
 */
export function convertCurrency(value) {
    value = '' + value;               // value is always a string
    value = value.replace(/,/g, ''); // Remove any commas
    value = value.replace(/\$/g, ''); // Remove any dollar signs
    value = parseFloat(value);        // Convert to float

    // 2 decimal places and appropriate commas + dollar sign
    return `$${(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

/**
 * Formats month string
 * @param  {string} date  Date string in format year-month-day
 * @return {string}       Appropriately formated string
 */
export function formatDate(date) {
    // Check for bad input
    if (!date || typeof date !== 'string') {
        return date;
    }
    const i = date.split('-');
    const year = i[0];
    const day = i[2];
    const month = i[1];
    return `${month}/${day}/${year}`;
}

export function generateKey(pre) {
    return `${pre}_${new Date().getTime()}`;
}

/**
 * Cleans input string of extra white space
 * @param  {string} string String to clean
 * @return {string}        String without extra white space
 */
export function cleanWhiteSpace(string) {
    return string.replace(/\s+/g, ' ').trim();
}

/**
 * Formats a JS date string into the format YYYY-MM-DD for use with the stats page (accessible by admins only)
 * @param { string } object A Javascript date object
 * @return { string}       String in the format YYYY-MM-DD
 */
export function formatDateForStats(date) {
    return (date) ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : '';
}
