import axios from 'axios'
import {catchAxios} from "./utils";


/**
 *  This function calls the API to retrieve all the payment methods saved to a users billing account.
 *  @function getPayMethods
 *  @param {int} accountNo - A billing account number.
 */
export const getPayMethods = async () => {
    const token = sessionStorage.token;
    let response;
    try {
        response = axios({
            method: 'post',
            url: '/payments/pay-methods',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    } catch (err) {
        response = [];
        catchAxios(err);
    }
    return response;
};

/**
 * Checks to see whether a credit card is expired
 * @function isExpired
 * @param {int} expMonth  - The month a card expires.  Expressed in the format XX
 * @param {int} expYear   - The year a credit card expires.  Expressed in the format XXXX
 */

// check card expiration
export const isExpired = (expMonth, expYear) => {
    const month = parseInt(expMonth);
    const year = parseInt(expYear);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    return ((year === currentYear && month <= currentMonth) || year < currentYear);
};


/**
 *  This function calls the API to check if the ACH payment can be handled
 *  @function checkAchPayment
 *  @param {payId} payment account ID, {number} payAmount
 */
export const checkAchPayment = async (payId, payAmount) => {
    const token = sessionStorage.token;
    let response;
    try {
        response = axios({
            method: 'post',
            url: '/payments/check-ach',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                payId, 
                payAmount,
            }
        });
    } catch (err) {
        response = [];
        catchAxios(err);
    }
    return response;
};