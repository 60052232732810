import React from 'react'
import {withRouter} from "react-router"
import {generateKey} from "./utils/utils";
import {Link} from 'react-router-dom';
import {Typography, makeStyles} from "@material-ui/core";
import style from './styles/Common.module.scss';

const useStyles = makeStyles(theme => ({
    breadcrumbs: {
        textTransform: 'uppercase',
    },
    container: {
        fontSize: '16px',
        padding: '20px',
        color: theme.palette.primary.main,
    }
}));

function History(props) {
    const classes = useStyles();
    // Builds a string that shows current router path
    const buildHistory = (location) => {
        let pathName = location.split('/');

        // Remove empty strings
        pathName = pathName.filter(e => {
            return e !== ""
        });
        return pathName;
    };

    let location = props.history.location.pathname;

    return (
        <div className={`${classes.container} ${style.mobileHideBreadcrumbs}`}>{
            buildHistory(location).map((e, i, arr) => {
                let link = '/';
                let y = 0;

                // Build each individual link in the path
                while (y <= i) {
                    link = link + `${arr[y]}/`;
                    y++;
                }

                return (
                    <React.Fragment key={generateKey(link)}>
                        <Link to={link}>
                            <Typography variant="h5" color="primary" component="span"
                                        className={classes.breadcrumbs}> {e} </Typography>
                        </ Link>
                        {(i !== (arr.length - 1)) &&
                        <Typography variant="h5" color="primary" component="span"
                                    className={classes.breadcrumbs}> > </Typography>
                        }
                    </React.Fragment>
                )
            })
        }
        </div>
    )
}

// Let history access router location
const HistoryWithRouter = withRouter(History);
export default HistoryWithRouter
