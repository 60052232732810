import React, {Component} from 'react';
import {wiline, stephouse} from './companyConfig.js';
import {siteType, siteTypeByBusinessUnit} from "../App";

export const AppContext = React.createContext();
/**
 *  Checks the url address to determine which context and theme will be used
 */
export const getBusinessContext = (businessUnit) => { //if businessUnit is undefined, it will get the context from the url
    if (siteTypeByBusinessUnit(businessUnit) === 'SNI') {
        return stephouse.contextConfig;
    } else if (siteTypeByBusinessUnit(businessUnit) === 'WNI') {
        return wiline.contextConfig;
    }
    return {};
};
/**
 * reducer used to set the state of PortalContext
 */
const reducer = (state, action) => {
    let customer = {}; // Declare customer outside of switch for re-use
    switch (action.type) {
        case 'SET_CUSTOMER':
            return {...state, customer: action.payload};
        case 'LOGOUT_USER':
            return {...state, customer: {}, businessUnit: siteType()};
        case 'SET_CUSTOMER_DETAILS':
            customer = state.customer;
            customer = {...customer, detailsData: action.payload};
            return {...state, customer};
        case 'SET_ACTIVE_BILLING_ACCOUNT':
            return {...state, activeBillingAccount: action.payload};
        case 'SET_PAYMENT_OPTIONS':
            return {...state, paymentOptions: action.payload};
        case 'REMOVE_PAYMENT_OPTION':
            const newOptions = state.paymentOptions.filter(x => {
                return x.pay_account_id !== action.payload
            });
            return {...state, paymentOptions: newOptions};
        case 'SET_BALANCE':
            return {...state, balance: action.payload};
        case 'SET_BUSINESS_UNIT':
            if (action.payload) {
                return {...state, businessUnit: action.payload, contextConfig: getBusinessContext(action.payload)};
            }
            break;
        case 'SET_BUSINESS_CONFIG':
            return {...state, contextConfig: action.payload};
        case 'SET_GLOBAL_LOAD':
            return {...state, globalLoad: action.payload};
        case 'SET_GLOBAL_MODAL':
            return {
                ...state, globalModal: {
                    open: (typeof action.payload.open !== 'undefined') ? action.payload.open : false,
                    title: (typeof action.payload.title !== 'undefined') ? action.payload.title : '',
                    content: (typeof action.payload.content !== 'undefined') ? action.payload.content : '',
                }
            };
        default:
            return {...state};
    }
};

export class ContextProvider extends Component {

    // This is state structure only
    state = {
        contextConfig: {
            id: 0,
            company: '',
            name: '',
            contact: '',
            address: {
                street: '',
                suite: '',
                city: '',
                state_long: '',
                state_short: '',
                zip: ''
            },
            phone: '',
            email: '',
            salesEmail: '',
            supportEmail: '',
            billingEmail: '',
            logo: '',
            logoAlt: '',
            color: '',
            portalUrl: ''
        },
        customer: {},
        activeBillingAccount: '',
        paymentOptions: [],
        balance: '',
        businessUnit: '',
        dialog: {
            open: false,
            msg: ''
        },
        globalLoad: false,
        globalModal: {
            open: false,
            title: '',
            content: ''
        },
        // this allows us to dynamically change the context values by calling dispatch
        // using similar workflow to redux
        dispatch: action => {
            this.setState(state => reducer(state, action));
        },
    };

    componentDidMount() {
        this.setState({contextConfig: getBusinessContext(this.state.businessUnit)});
    }

    // TODO can be optimized by using shouldComponentUpdate() react lifecycle method
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({contextConfig: getBusinessContext(this.state.businessUnit)})
        }
    }

    render() {
        return (
            <AppContext.Provider value={{
                state: this.state
            }}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}

