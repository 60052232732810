import React, {useContext} from 'react';
import {generateKey} from "./utils/utils";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {AppContext} from "../contexts/PortalContext";

function GlobalModal() {
    const context = useContext(AppContext);
    const closeModal = () => {
        context.state.dispatch({
            type: 'SET_GLOBAL_MODAL',
            payload: {open: false}
        });
    };

    return (
        <Dialog
            open={context.state.globalModal.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <>
                <DialogTitle id="alert-dialog-title">{context.state.globalModal.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText key={generateKey('d_0')} variant="body1" color="primary" align="center">
                        {context.state.globalModal.content}
                    </DialogContentText>
                </DialogContent>
            </>
            <DialogActions>
                <Button onClick={closeModal} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GlobalModal;