import React from 'react';
import ssl from '../assets/img/ssl-logo.png';
import style from './styles/Footer.module.scss';
import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AppContext} from "../contexts/PortalContext";
import ErrorBoundary from "./ErrorBoundary";

function Footer() {
    // Used for the footer copyright date
    const newDate = (new Date()).getFullYear();

    return (
        <AppContext.Consumer>
            {(context) => (
                <ErrorBoundary>
                    <div id="footer" style={{marginTop: '2vh'}}>
                        <div className="img-container">
                            <div className={style.centerBot}>
                                <img className={style.imgSize} src={ssl} alt="SSL"/>
                                <div className={style.iconContainer}>
                                    <FontAwesomeIcon className={style.icon} icon={['fab', 'cc-visa']}/>
                                    <FontAwesomeIcon className={style.icon} icon={['fab', 'cc-mastercard']}/>
                                    <FontAwesomeIcon className={style.icon} icon={['fab', 'cc-amex']}/>
                                    <FontAwesomeIcon className={style.icon} icon={['fab', 'cc-discover']}/>
                                </div>
                            </div>
                        </div>
                        <div className={style.copyright}>
                            <Typography variant="body1">&copy; {newDate} {context.state.contextConfig.name}</Typography>
                        </div>
                    </div>
                </ErrorBoundary>
            )}
        </AppContext.Consumer>
    )
}

export default Footer;
